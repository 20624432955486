import { Component, EventEmitter, OnInit } from '@angular/core';
import { interval, Observable, forkJoin, Subscription, of } from 'rxjs';
import { VizPublicationService } from '../../analysis-dataset/analysis-refine-content/analysis-refinement.service';
import { VizProject } from '../../models/project.model';
import { Router } from '@angular/router';
import { GeovizService } from '../../viz/geoviz.service';
import * as _m from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'tdms-analysis-projects',
  templateUrl: './analysis-projects.component.html',
  styleUrls: ['./analysis-projects.component.scss']
})
export class AnalysisProjectsComponent implements OnInit {

  maxProjects = 10;

  yourProjects:VizProject[] = [];
  selectedProjects:string[] = [];
  isLoading = true;
  corpusSubscription:Subscription;
  projectSubscription:Subscription;
  deleteSubscription:Subscription;
  geoSubscription:Subscription;
  pullingSubscription:Subscription;
  hover:string = '';
  failedHover:string = '';

  constructor(private pubService: VizPublicationService, private router: Router, private dialog: MatDialog, private geoService:GeovizService) { }

  ngOnInit() {
    if(localStorage.getItem('vizCreated')){
      let count = 0;
      this.pullingSubscription = interval(2000).subscribe(val => {
        count++;
        this.getProjects();
        if(count > 11){ this.pullingSubscription.unsubscribe(); }
      });
    } else {
      this.getProjects();
    }
    
  }

  onDestroy(){
    if(this.corpusSubscription){this.corpusSubscription.unsubscribe();}
    if(this.projectSubscription){this.projectSubscription.unsubscribe();}
    if(this.deleteSubscription){this.deleteSubscription.unsubscribe();}
    if(this.geoSubscription){this.geoSubscription.unsubscribe();}
    if(this.pullingSubscription){this.pullingSubscription.unsubscribe();}
  }

  showProject(project:VizProject){
    if(this.selectedProjects.includes(project.id)){
      this.selectedProjects = this.selectedProjects.filter(projectId => projectId != project.id);
    } else {
      this.selectedProjects.push(project.id);
    }
  }

  isLoadingComplete() {
    if(!this.isLoading) {
      this.sortProject(this.isLoading);
      return true;
    }
    return false;
  }
  

  isGeoAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "geography")){
      return true;
    }
    return false;
  }

  isTopicAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "topic")){
      return true;
    }
    return false;
  }

  isSentimentAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "sentiment")){
      return true;
    }
    return false;
  }

  getMethodId(project:VizProject, methodType:string):string{
    if( project.methods && 
        project.methods.method && 
        project.methods.method.find(method =>  method.methodType === methodType)) {
        
          return project.methods.method.find(method => method.methodType === methodType).id;
    }
    return '';
  }

  getProjectCount():number{
    return this.yourProjects.filter((project:VizProject) => project.status !== 'deleting').length
  }

  getProjects(){
    this.yourProjects = [];
    this.corpusSubscription = this.pubService.getProjects().subscribe(projectList => {
      if(projectList.corpus.length < 1){
        this.isLoading = false;
      } else if(localStorage.getItem('vizCreated') && projectList.corpus.filter(corpus => corpus.name === localStorage.getItem('vizCreated')).length < 1){
        this.isLoading = true;
      } else {
        localStorage.removeItem('vizCreated');
        if(this.pullingSubscription) { this.pullingSubscription.unsubscribe();}
        projectList.corpus.forEach(corpus => {
          this.getProductInfo(corpus.id, projectList.corpus.length);
        });
      }
      },
      (error => {
        if(this.pullingSubscription){this.pullingSubscription.unsubscribe();}
        console.log(error);
        this.isLoading = false;
      }));
  }

  getProductInfo(corpusId:string, corpusListLength:number){
    this.projectSubscription = this.pubService.getProjectsDetails(corpusId).subscribe(project => {
      if(project.status === 'dataloaded'){
        this.setStatus(project, corpusListLength);
      }else{
        this.yourProjects.push(project);
        this.isLoading = this.yourProjects.length < corpusListLength;
      }
    },
    (error => {
      console.log(error);
      this.isLoading = false;
      this.sortProject(this.isLoading);

        if(this.pullingSubscription) {
          this.pullingSubscription.unsubscribe();
        }
      }));
  }

  sortProject(isLoading: boolean) {
    if(!isLoading) {
      let tmpProject = this.yourProjects;
      tmpProject.sort((a,b) => {
        let dateA = new Date(a.dateCreated).getTime();
        let dateB =  new Date(b.dateCreated).getTime();
        return dateB > dateA ? 1 : -1;
      });   
      this.yourProjects = tmpProject; 
    }
  }

  setStatus(project:VizProject, corpusListLength:number){

    let methodInfoRequests:Observable<any>[] = [];
    if(this.isGeoAnalysis(project)){methodInfoRequests.push(this.geoService.getGeoCorpusInfo(this.getMethodId(project, "geography")).pipe(catchError(e => of({status:"failed"})))) }
    if(this.isTopicAnalysis(project)){ methodInfoRequests.push(this.geoService.getTopicCorpusInfo(this.getMethodId(project, "topic")).pipe(catchError(e => of({status:"failed"})))) }
    if(this.isSentimentAnalysis(project)){ methodInfoRequests.push(this.geoService.getSentimentCorpusInfo(this.getMethodId(project, "sentiment")).pipe(catchError(e => of({status:"failed"})))) }

    this.geoSubscription = forkJoin(methodInfoRequests).subscribe(methodInfo => {
      console.log(methodInfo)
      let i = 0;
      if(this.isGeoAnalysis(project)){ project.methods.method.find(method => method.methodType === "geography").status = methodInfo[i].status; i++; }
      if(this.isTopicAnalysis(project)){ project.methods.method.find(method => method.methodType === "topic").status = methodInfo[i].status; i++; }
      if(this.isSentimentAnalysis(project)){ project.methods.method.find(method => method.methodType === "sentiment").status = methodInfo[i].status; i++;}

      this.yourProjects.push(project);
      this.isLoading = this.yourProjects.length < corpusListLength;
      if(this.pullingSubscription){this.pullingSubscription.unsubscribe();}

    },(error => {
      if(this.isGeoAnalysis(project)){ project.methods.method.find(method => method.methodType === "geography").status = "failed" }
      if(this.isTopicAnalysis(project)){ project.methods.method.find(method => method.methodType === "topic").status = "failed" }
      if(this.isSentimentAnalysis(project)){ project.methods.method.find(method => method.methodType === "sentiment").status = "failed"}

      this.yourProjects.push(project);
      this.isLoading = this.yourProjects.length < corpusListLength;
      if(this.pullingSubscription){this.pullingSubscription.unsubscribe();}
      console.log(error);
    }))

  }

  
  getGeoStatus(project: VizProject): string {
    return this.isGeoAnalysis(project) ? project.methods.method.find(method => method.methodType === "geography").status : '';
  }

  getTopicStatus(project: VizProject): string { 
    return this.isTopicAnalysis(project) ? project.methods.method.find(method => method.methodType === "topic").status : '';
  }

  getSentimentStatus(project: VizProject): string {
    return this.isSentimentAnalysis(project) ? project.methods.method.find(method => method.methodType === "sentiment").status : '';
  }


  getPublications(project:VizProject):string{
    if(project.search.filters && project.search.filters.find(filter => filter.name === "pubTitle")){
      return project.search.filters.find(filter => filter.name === "pubTitle").entries.map(pub => pub.searchValue).join(", ");
    }
    return '';
  }

  getDate(project: VizProject): string {
    if(project.minDate && project.maxDate){
      return _m(project.minDate).format('MMM DD, YYYY') + ' to \n' + _m(project.maxDate).format('MMM DD, YYYY');
    } else if (project.minDate){
      return 'After ' + _m(project.minDate).format('MMM Do, YYYY');
    } else if(project.maxDate){
      return 'Before ' + _m(project.maxDate).format('MMM Do, YYYY');
    }
    return '';
  }

  goToGeoAnalysis(project:VizProject){
    var methodId:string = this.getMethodId(project, "geography");
    if (!localStorage.getItem("geo-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("geo-map-data-" + methodId, JSON.stringify(proj));
    }
    
    this.router.navigateByUrl("analysis/viz/geoanalysis/" + methodId);
  }

  goToTopicAnalysis(project:VizProject){
    var methodId:string = this.getMethodId(project, "topic");
    if (!localStorage.getItem("tm-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("tm-map-data-" + methodId, JSON.stringify(proj));
    }
    
    this.router.navigate(["analysis/viz/tm/", methodId]);
  }

  goToSentimentAnalysis(project: VizProject) {
    var methodId:string = this.getMethodId(project, "sentiment");
    if (!localStorage.getItem("sa-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("sa-map-data-" + methodId, JSON.stringify(proj));
    }
    this.router.navigate(['analysis/viz/sa', methodId ]);
  }
  

  delete(project:VizProject){
    const dialogRef: MatDialogRef<AnalysisDeleteModal> = this.dialog.open(AnalysisDeleteModal, { disableClose: true, width: '600px', maxWidth:'850px' });
    dialogRef.componentInstance.project = project;
    dialogRef.componentInstance.deleteEvent.subscribe((project:VizProject) => {
      localStorage.removeItem("geo-map-data-" + this.getMethodId(project, "geography"));
      this.deleteSubscription = this.pubService.deleteProject(project.id).subscribe(response => {
        this.selectedProjects = [];
        this.yourProjects = [];
        this.isLoading = true;
        setTimeout(()=> this.getProjects(), 10000);},
      (error => {
        console.log("Project Deletion Error: ", project.id + " - " + JSON.stringify(error));
        this.selectedProjects = [];
        this.yourProjects = [];
        this.isLoading = true;
        if(error.status === 200){
          setTimeout(()=> this.getProjects(), 10000);
        } else {
          this.getProjects();
        }
      }));
    });
    
  }

  clearStorage(){
    var clearKeys:string[] = [ "datasetType", "docCount", "length", "selectedDbList", "vsReq", "pubList","searchTerm","sourceFilters","pubFilters","pubFacets"];
    clearKeys.forEach(key => {
      localStorage.removeItem(key);
    })

    let obj = {
      geoCardSelection: true,
      tmCardSelection: true,
      saCardSelection: true,
    }

    localStorage.setItem('vizcardselection', JSON.stringify(obj));

    


  }

  getInteractablityStatus(project:VizProject):boolean{
    if(project.status === 'failed'){
      return true;
    }
    if (project.status == 'dataloaded'){
      if(this.isGeoAnalysis(project) && (this.getGeoStatus(project) === 'completed' || this.getGeoStatus(project) === 'failed')){
        return true;
      }
      if(this.isTopicAnalysis(project) && (this.getTopicStatus(project) === 'completed' || this.getTopicStatus(project) === 'failed')){
        return true;
      }
      // if(this.isSentimentAnalysis(project) && (this.getSentimentStatus(project) === 'completed' || this.getSentimentStatus(project) === 'failed')){
      if(this.isSentimentAnalysis(project)){
        return true;
      }
      return false;
    }
    return false;
    
  }

}

@Component({
  selector: 'analysis-delete-modal',
  templateUrl: 'analysis-delete-modal.html',
  styleUrls: ['./analysis-projects.component.scss']
})
export class AnalysisDeleteModal {
  project:VizProject
  deleteEvent = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<AnalysisDeleteModal>){}

  onDelete() {
    this.deleteEvent.emit(this.project);
    this.dialogRef.close();
  }


}
