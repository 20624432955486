<div class="progress_div">
    <div class="progress_bar">
        <div class="dot highlighted"></div>
        <hr  [ngClass]="(stage === 'find' || stage === 'refine' || stage === 'create' ) ? 'rod highlighted' : 'rod unhighlighted'"/>

        <div [ngClass]="(stage === 'find' || stage === 'refine' || stage === 'create' ) ? 'dot highlighted' : 'dot unhighlighted'"></div>
        <hr  [ngClass]="(stage === 'find' || stage === 'refine' || stage === 'create' ) ? 'rod highlighted' : 'rod unhighlighted'"/>


        <div [ngClass]="(stage === 'refine' || stage === 'create') ? 'dot highlighted' : 'dot unhighlighted'"></div>
        <hr  [ngClass]="(stage === 'refine' || stage === 'create') ? 'rod highlighted' : 'rod unhighlighted'"/>

        <div [ngClass]="stage === 'create' ? 'dot highlighted' : 'dot unhighlighted'"></div>



        <!-- <hr class="rod"/> -->

        <!-- <div class="highlighted">
            <div class="dot"></div>
        </div>
        
        <div [ngClass]="(stage === 'find' || stage === 'refine' || stage === 'create' ) ? 'highlighted' : 'unhighlighted'">
            <hr class="rod"/>
            <div class="dot"></div>
        </div>

        <div [ngClass]="(stage === 'refine' || stage === 'create') ? 'highlighted' : 'unhighlighted'">
            <hr class="rod"/>
            <div class="dot"></div>
        </div>

        <div [ngClass]="stage === 'create' ? 'highlighted' : 'unhighlighted'">
            <hr class="rod"/>
            <div class="dot"></div>
        </div>
         -->
    </div>
    
    <div class = "progress_labels">
        <div class="first label"><a routerLink='/analysis/select'>Select Visualization</a></div>

        <!--search Stage-->
        <div *ngIf="(stage === 'find' || stage === 'refine' || stage === 'create' )" 
            [ngClass]="(stage === 'find') ? 'highlighted' : 'unhighlighted'" class="second label">
            <a routerLink='/analysis/search'>Search Content</a>
        </div>

        <div *ngIf="!(stage === 'find' || stage === 'refine' || stage === 'create' )" 
            [ngClass]="(stage === 'find' || stage === 'refine' ) ? 'highlighted' : 'unhighlighted'" class="second label">
            Search Content
        </div>


        <!--Refine Stage-->
        <div *ngIf="(stage === 'refine' || stage === 'create')" 
            [ngClass]="(stage === 'refine') ? 'highlighted' : 'unhighlighted'" class="third label">
            <a *ngIf="(stage === 'refine' || stage === 'create')" routerLink='/analysis/refinecontent'>Refine Content</a>
        </div>
        <div *ngIf="!(stage === 'refine' || stage === 'create')" 
            [ngClass]="(stage === 'refine') ? 'highlighted' : 'unhighlighted'" class="third label">
            Refine Content
        </div>        

        <!--Create Project-->
        <div [ngClass]="stage === 'create' ? 'highlighted' : 'unhighlighted'" class="label">Create Project</div>
    </div>
</div>