import { Component, OnInit } from '@angular/core';
import { VizProject } from '../../models/project.model';

@Component({
  selector: 'tdms-analysis-sample-projects',
  templateUrl: './analysis-sample-projects.component.html',
  styleUrls: ['./analysis-sample-projects.component.scss']
})
export class AnalysisSampleProjectsComponent implements OnInit {

  sampleProjects:VizProject[] = [];

  selectedProjects:string[] = [];

  constructor() { }

  ngOnInit() {
  }

  showProject(project:VizProject){
    if(this.selectedProjects.includes(project.name)){
      this.selectedProjects = this.selectedProjects.filter(projectName => projectName != project.name);
    } else {
      this.selectedProjects.push(project.name);
    }
  }

  delete(project:VizProject){
    this.sampleProjects = this.sampleProjects.filter(proj => proj.name != project.name);
  }

  clearStorage(){
    var clearKeys:string[] = [ "datasetType", "docCount", "length", "selectedDbList", "vsReq", "pubList","searchTerm","sourceFilters","pubFilters","pubFacets"];
    clearKeys.forEach(key => {
      localStorage.removeItem(key);
    })
  }

}
