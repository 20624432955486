import { Component, OnInit, OnDestroy, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, of, from, Subject, concat } from 'rxjs';
import { PublicationService } from './publication-results/publication-refinement.service';
import { Hit, Facet, Entry } from 'src/app/_shared/models/publication-results.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map, tap, shareReplay, toArray, catchError } from 'rxjs/operators';
import { Database } from 'src/app/_shared/models/publication-search.model';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import * as _m from 'moment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'tdms-publication-search',
  templateUrl: './publication-refinecontent.component.html',
  styleUrls: ['./publication-refinecontent.component.scss']
})
export class PublicationRefineContentComponent implements OnInit {

  searchField: FormControl;
  error: string ='';
  clearFilter: boolean = false;
  isPublication = false;
  isShowFilters = false;

  @ViewChild('filterOverlay', {static:false}) set content(content: ElementRef) {
    if(content) { 
        let height = content.nativeElement.offsetHeight;
        content.nativeElement.style.marginBottom = "-" + height + "px";
        console.log(content)
    }
  };

  //searchTermSubject and respective action stream...
  private searchTermSubject = new BehaviorSubject<string>("");
  searchTermAction$ =  this.searchTermSubject.asObservable();


  //currently selected workbech observable.
  currentlySelectedWb$ =  of(localStorage.getItem('workbench'));

  //prepare observable for selected PubIds
  selectedPubIds$ =  from(JSON.parse(localStorage.getItem('selectedPubs')) === null ? []:  JSON.parse(localStorage.getItem('selectedPubs')))
                      .pipe(
                        map( val => ({"searchValue": val})),
                        toArray(),
                        map( val => JSON.stringify(val)),
                        tap( val => console.log("selectedPubIds => ", val)
                      )
  );

  //prepare observable for selected Db's
  dbSelection: Database[] = localStorage.getItem('selectedDbList') !== null ? JSON.parse(localStorage.getItem('selectedDbList')) : [];

  selectedDbs$ =  from(this.dbSelection)
                      .pipe(
                        map( db => ({"moniker": db.moniker, "name": db.name})),
                        toArray(),
                        map( val => JSON.stringify(val)),
                        tap( val => console.log("selectedDB's => ", val))
                      );


  // observable for sourceType and docType filters..
  sTypeFilters$ = this.pubservice.sourceTypeFilter$

  docFilters$ = this.pubservice.docTypeFilter$

  displayFilters$ = this.pubservice.displayFilter$;

  pubOrderAsc$ =  of('pubDateAsc');

  pubOrderDesc$ = of('pubDateDesc');
                

  //combine all the observables before search request.
  searchRequestDesc$ = combineLatest([this.searchTermAction$, this.currentlySelectedWb$, this.selectedPubIds$, this.selectedDbs$,
                                  this.pubOrderDesc$, this.sTypeFilters$, this.docFilters$])
                    .pipe(
                      //tap(val => console.log('searchRequest start =>', val)),
                      map(([searchTerm, wbId, publications, dbs, sortOrderDesc, sTypeFilters, docFilters]) => {
                        return this.pubservice.generateSearchRequest(searchTerm, wbId, sortOrderDesc)
                      }),
                      tap( val => console.log( 'SQ =>', val))
                    );

  searchRequestAsc$ = combineLatest([this.searchTermAction$, this.currentlySelectedWb$, this.selectedPubIds$, this.selectedDbs$,
    this.pubOrderAsc$, this.sTypeFilters$, this.docFilters$, this.pubOrderDesc$])
        .pipe(
          //tap(val => console.log('searchRequest start =>', val)),
          map(([searchTerm, wbId, publications, dbs, sortOrderAsc, sTypeFilters, docFilters]) => {
            return this.pubservice.generateSearchRequest(searchTerm, wbId, sortOrderAsc)
          }),
          tap( val => console.log( 'SQ =>', val))
        ); 
        
        
  //Build resultsSteam based on searchRequest stream.
  docresultsDesc$ = this.searchRequestDesc$.pipe(
    tap(searchReq =>  console.log('searchReq =>', JSON.stringify(searchReq))),
    switchMap(searchReq => 
      concat(
        of( { type: 'start'}),
        this.pubservice.search(searchReq)
                        .pipe( 
                            map(searchResp => ({ type: 'finish', searchResp})),
                            catchError(err => of( { type: 'finish', err}))
                        )
      ))
  );

  docresultsAsc$ = this.searchRequestAsc$.pipe(
    tap(searchReq =>  console.log('searchReq =>', JSON.stringify(searchReq))),
    switchMap(searchReq => 
      concat(
        of( { type: 'start'}),
        this.pubservice.search(searchReq)
                        .pipe( 
                            map(searchResp => ({ type: 'finish', searchResp})),
                            catchError(err => of( { type: 'finish', err}))
                        )
      ))
  );

  //Following observable to collect the date range from Asc and Dec requests.
  dateRange$ =  combineLatest([this.docresultsDesc$, this.docresultsAsc$])
                  .pipe(
                    tap(([descResultsObj, ascResultsObj]) => {

                    })
                  );
                  
  constructor(private pubservice: PublicationService, private dataSetService: DatasetService, private titleService: Title) { }


  ngOnInit() {
      this.titleService.setTitle("Create new dataset: Refine content");
      this.searchField = new FormControl(this.pubservice.currentSearchTerm());
      this.applyFilters();
      this.isPublication =  this.dataSetService.isPublication();

      this.scrollToTop();
  }

  public search(searchValue: string) {
    this.searchField.setValue(searchValue);
    localStorage.setItem("searchValue" , searchValue);
    this.searchTermSubject.next(searchValue);
  }

  isDocs(docsFound:number):boolean{
    return docsFound > 0;
  }

  pubFacets(facets: Facet[]) {
    if(facets){
      const pubfacets = this.pubservice.getPubFacets(facets);
      localStorage.setItem("pubFacets", JSON.stringify(pubfacets));
      return pubfacets;
    }
    return JSON.parse(localStorage.getItem("pubFacets"));
    
  }

  sourceFacets(facets: Facet[]) {
    return this.pubservice.getSourceFacets(facets);
  }

  docFacets(facets: Facet[]) {
    return this.pubservice.getDocTypeFacets(facets);
  }

  applyFilters(searchValue?: string) {

    if(searchValue){
      this.searchTermSubject.next(searchValue);
    } else {
      this.searchTermSubject.next(localStorage.getItem("searchValue") ? localStorage.getItem("searchValue"): "");
    }
    

    from(this.docFilterValues())
    .pipe(
      map(val => ({"searchValue": val})),
      tap(val => console.log(val)),
      toArray(),
      map(val => JSON.stringify(val)),
      tap( val => console.log(val))
    ).subscribe(val =>  {
        console.log("docFilter >>>>> ", val)
        this.pubservice.setDocTypeFilterSubject(val)
    })

    from(this.sourceFilterValues())
    .pipe(
        map(val => ({"searchValue": val})),
        tap(val => console.log(val)),
        toArray(),
        map(val => JSON.stringify(val)),
        tap( val => console.log(val))
      ).subscribe(val => {
        console.log("sourceFilter >>>>> ", val)
        this.pubservice.setSourceFilterSubject(val)
      })

      this.isShowFilters = false;

      this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }

 
  private sourceFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('sourceFilters'));
    if(val !=null && val.length >0) 
      return val;
    else
       return [];  
    
  }

  private docFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('docFilters'));
    if(val !=null && val.length >0) {
      return val;
    } else {
       return [];  
    }
     
  }

  

  public displayClearFilters(): boolean | false {
    return this.pubservice.displayClearFilters();

  }

  openSearchTipsPage() {
    window.open("https://www.proquest.com/help/academic/webframe.html?Search_Tips.html#Search_Tips.html", "_blank");
  }

  getFooterHeight(numOfDocs:number):number{
    
    if(numOfDocs < 100000){
      return 92;
    }

    const extraDigits = numOfDocs.toString().length - 5;
    const commas = Math.ceil(numOfDocs.toString().length / 3) - 1;

    return 92 + (extraDigits*9) + (commas*5);
  }

  onToggleShowFilters(){
    this.isShowFilters = !this.isShowFilters;
  }
  formatDate(date:string):string{
    return _m(new Date(date)).utcOffset(0,false).format('MMM DD, YYYY');
  }

  isAppliedFilters(){
    return  localStorage.getItem('docFilters') || localStorage.getItem('sourceFilters') || localStorage.getItem('fromDate') ||  localStorage.getItem('toDate');
  }

}
