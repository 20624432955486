import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { Console } from "console";
import { Subscription } from "rxjs";
import {
  Publication,
  VizDocSearchRequest,
} from "src/app/_shared/models/publication-results.model";
import { Method, Methods, VizPreProject } from "../../models/project.model";
import { VizPublicationService } from "../analysis-refine-content/analysis-refinement.service";

@Component({
  selector: "tdms-analysis-create-content",
  templateUrl: "./analysis-create-content.component.html",
  styleUrls: ["./analysis-create-content.component.scss"],
})
export class AnalysisCreateContentComponent implements OnInit {
  isLoading: boolean = true;
  isQuery: boolean = false;
  isShowQuery: boolean = false;
  isCreating: boolean = false;
  isError: boolean = false;
  errorMessage: string = "";

  NONAME: string = "Please enter a name.";
  INVALIDNAME: string = "The field you entered is invalid. Please try again";

  createProject: any;
  docCount: string;
  publications: string;
  query: string;
  pubResponse: any;
  searchRequest: VizDocSearchRequest;
  creationSubscription: Subscription;
  pubResponseSubcription: Subscription;
  workbenchId: string;
  selectedVizText: string;

  constructor(
    private formBuilder: FormBuilder,
    private pubService: VizPublicationService,
    private dialog: MatDialog
  ) {
    this.createProject = this.formBuilder.group({
      name: "",
    });
  }

  ngOnInit() {
    this.searchRequest = JSON.parse(localStorage.getItem("vsReq"));
    this.docCount = localStorage.getItem("vDocCount");
    this.isLoading = false;
    this.selectedVizText = this.getSelectedVizualizations();
  }

  ngDestroy() {
    if (this.creationSubscription) {
      this.creationSubscription.unsubscribe();
    }
    if (this.pubResponseSubcription) {
      this.pubResponseSubcription.unsubscribe();
    }
  }

  getContentList(): string {
    return JSON.parse(localStorage.getItem("pubFacets"))
      ? JSON.parse(localStorage.getItem("pubFacets"))
          .map((pub) => pub.displayValue)
          .join(", ")
      : "";
  }

  savePublicationData(pubList) {
    this.searchRequest.search.publications.map((pubId) => {
      var publication = pubList.find((pub) => pub.id === pubId.searchValue);
      pubId.displayValue = publication.field
        .find((element) => element.name === "title")
        .value.toString();
    });
  }

  toggleQuery() {
    this.isShowQuery = !this.isShowQuery;
  }

  onCreate(projectData) {
    if (!projectData.name) {
      this.isError = true;
      this.errorMessage = this.NONAME;
    } else {
      this.isCreating = true;
      this.creationSubscription = this.pubService
        .createProject(this.getProject(projectData.name))
        .subscribe(
          (response) => {
            localStorage.setItem("vizCreated", projectData.name);
            this.dialog.open(ProjectCreateSuccessModal, {
              disableClose: true,
              maxWidth: "850px",
            });
            this.isCreating = false;
          },
          (error: HttpErrorResponse) => {
            console.log("Error: ", error);
            this.isCreating = false;
          }
        );
    }
  }

  private getProject(projectname: string): VizPreProject {
    if (
      this.searchRequest.search.filters.filter(
        (filter) => filter.name === "pubTitle"
      ).length < 1
    ) {
      this.addPubsToSearch();
    }
    const project: VizPreProject = {
      name: projectname,
      search: this.searchRequest.search,
      methods: this.selectedMethods()
    };

    return project;
  }

  private selectedMethods(): Methods {
    let method: Method = { methodType: ''};
    let methods: Method[] = [];
    let allSelectedMethods: Methods = {method: methods};
    let vizSelection: {geoCardSelection: boolean, tmCardSelection: boolean, saCardSelection: boolean} =  JSON.parse(localStorage.getItem('vizcardselection'));
    if(vizSelection.geoCardSelection === true) {
      method.methodType = 'geography'
      methods.push(method);
    }
    if(vizSelection.tmCardSelection === true) {
      method = { methodType: ''};
      method.methodType = 'topic'
      methods.push(method);
    }
    if(vizSelection.saCardSelection === true) {
      method = { methodType: ''};
      method.methodType = 'sentiment'
      methods.push(method);
    }
    allSelectedMethods.method =  methods;
    console.log('allselected Methods ------> '+JSON.stringify(allSelectedMethods));
    return allSelectedMethods;

  }

  addPubsToSearch() {
    let pubList: Publication[] = JSON.parse(localStorage.getItem("pubFacets"));
    let entryList = [];
    pubList.forEach((pub) => {
      entryList.push({
        searchValue: pub.searchValue,
      });
    });
    let pf = {
      name: "pubTitle",
      entries: pubList,
    };
    this.searchRequest.search.filters.push(pf);
  }

  getSelectedVizualizations() {
    let rtnText = "";
    let cardObj = JSON.parse(localStorage.getItem("vizcardselection"));
    //let obj = {'geoCardSelection': this.geoCardCheck, 'tmCardSelection': this.tmCardCheck};
    if (cardObj.geoCardSelection === true) {
      rtnText = "Geographic Analysis";
    }
    if (rtnText.length > 0 && cardObj.tmCardSelection === true) {
      rtnText = rtnText + ", " + "Topic Modeling";
    } else if (cardObj.tmCardSelection === true) {
      rtnText = "Topic Modeling";
    }
    if (rtnText.length > 0 && cardObj.saCardSelection === true) {
      rtnText = rtnText + ", " + "Sentiment Analysis";
    } else if (cardObj.saCardSelection === true) {
      rtnText = "Sentiment Analysis";
    }

    return rtnText;
  }
}

@Component({
  selector: "project-create-success-modal",
  templateUrl: "project-create-success-modal.html",
  styleUrls: ["./analysis-create-content.component.scss"],
})
export class ProjectCreateSuccessModal {
  constructor(
    public dialogRef: MatDialogRef<ProjectCreateSuccessModal>,
    private router: Router
  ) {}

  onSuccess() {
    this.router.navigateByUrl("/analysis/dashboard");
    this.clearStorage();
    this.dialogRef.close();
  }

  private clearStorage() {
    var clearKeys: string[] = [
      "vDocCount",
      "vsReq",
      "vPubList",
      "searchTerm",
      "vPubFilters",
      "pubFacets",
    ];
    clearKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}
