import { NgModule, APP_INITIALIZER } from '@angular/core';
import { PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { AppFooterComponent } from './app-footer';
import { AppHeaderComponent } from './app-header';

import '../styles.scss';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CallbackComponent } from './auth/callback/callback.component';
// Application wide providers
import { AppRoutingModule } from './app-routing.module';
import { AdminHeaderComponent } from './app-header/admin-header.component';
import { NoContentModule } from './no-content';
import { UnautorizedComponent } from './unautorized/unautorized.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { AnalysisModule } from './analysis';
import { AuthGuard } from './auth/auth.guard';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateAccountModule } from './create-account';
import { OnbordingBannerComponent } from './onbording-banner/onbording-banner.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleTimeOutModalComponent } from './idle-time-out-modal.component';
import { ExternalLinkDirective } from './directives/external-link.directive';

const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  Title,
  PathLocationStrategy,
  AppState,
];
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
    CallbackComponent,
    AdminHeaderComponent,
    UnautorizedComponent,
    WelcomeComponent,
    IdleTimeOutModalComponent,
    OnbordingBannerComponent,
    ExternalLinkDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    NoContentModule,
    ClickOutsideModule,
    AnalysisModule,
    CreateAccountModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  entryComponents:[
    IdleTimeOutModalComponent
  ],
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS
  ],
})
export class AppModule {
  constructor() {
    // checks to see if the app url path is valid format and redirects the app to the 404 page if it is not
    const uriPath = window.location.pathname + window.location.search;

    try {
      decodeURIComponent(uriPath);
    } catch (e) {
      let uri = window.location.protocol + '//' + window.location.hostname;
      const profile = Cookie.get('profile');

      if (window.location.port) {
        uri = uri + ':' + window.location.port;
      }

      if (uri.indexOf('/' + profile) === -1) {
        uri = uri + '/' + profile;
      }

      // default location for the redirect uri is the root of the app. Could add a redirect uri page but it would only
      // have a loader graphic becuase all navigation is handled by the responses from api calls
      uri = uri + '/404';

      window.location.replace(uri);

    }
  }
}
