import { Injectable } from '@angular/core';
import { IPubResultsList, PubSearchRequest, PublicationResponse, Database, DbResponse, Search } from 'src/app/_shared/models/publication-search.model';
import { IPubResultItem } from 'src/app/_shared/models/pubresult-item.model';
import { BehaviorSubject, Observable, Subject, throwError, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PubDocumentSearchResponse, DocSearchRequest, VizDocSearchRequest, SearchRequest, Entry, Facet, Filter } from 'src/app/_shared/models/publication-results.model';
import { tap, map, catchError, switchMap, shareReplay, toArray } from 'rxjs/operators';
import { VizCorpusResponse, VizPreProject, VizProject } from '../../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class VizPublicationService {

  docApiRootForMcm = '/api/mcm/document/search';

  constructor(private http: HttpClient) { }


  private docCountSubject = new Subject<number>();
  totalDocCount$ =  this.docCountSubject.asObservable();

  private searchRequestSubject = new Subject<DocSearchRequest>();
  searchRequest$ = this.searchRequestSubject.asObservable();

   //Build Facets stream
   private pubFacetSubject = new Subject<Entry[]>();
   pubFacet$ = this.pubFacetSubject.asObservable();
 
   private sourceFacetSubject = new Subject<Entry[]>();
   sourceFacet$ = this.sourceFacetSubject.asObservable();
 
   private objectFacetSubject = new Subject<Entry[]>();
   objectFacet$ = this.objectFacetSubject.asObservable();


   //Build Filters --> for search Request --> Filters in transforme form i.e., {"searchValue": "source Journal"}
   private docTypeFilterSubject = new BehaviorSubject<string>('');
   docTypeFilter$ = this.docTypeFilterSubject.asObservable();

   private sourceTypeFilterSubject = new BehaviorSubject<string>('');
   sourceTypeFilter$ = this.sourceTypeFilterSubject.asObservable();

   private pubFilterSubject = new BehaviorSubject<string>('');
   pubFilter$ = this.pubFilterSubject.asObservable();


   //Define subject for user currently selected filters. 
   private currSelectedObjectTypesSubject = new BehaviorSubject<Array<string>>([]);
   currSelectedObjectTypes$ = this.currSelectedObjectTypesSubject.asObservable();

   private currSelectedSourceTypesSubject = new BehaviorSubject<Array<string>>([]);
   currSelectedSourceTpes$ = this.currSelectedSourceTypesSubject.asObservable();

   private dateFilterSubject = new Subject<boolean>();
   dateFilter$ = this.dateFilterSubject.asObservable();

   private fullTextLimiterSubject = new BehaviorSubject<boolean>(false);
   fullTextLimitter$ = this.fullTextLimiterSubject.asObservable();

   //DisplayFilter Subject  (button)
   private displayFilterSubject = new BehaviorSubject<boolean>(false);
   displayFilter$ = this.displayFilterSubject.asObservable();


  private handleError(err: any) {
    let errorMessage: string;
    if(err.error instanceof ErrorEvent) {
      //network error
      errorMessage = `An error occurred: ${err.error.errorMessage}`;
    } else {
      //backend error
      errorMessage =  `Error occurred: (${err.status}: ${err.body.error})`;
    }
    console.error(err);
    return throwError(errorMessage);

    return null;
  }

  public setSearchRequest(req: DocSearchRequest) {
    console.log("req => ", req);
    this.searchRequestSubject.next(req);
  }

  search(docSearchReq: VizDocSearchRequest): Observable<PubDocumentSearchResponse> {
    //store request into local store
    this.storeSearchRequest(docSearchReq);    
    return this.http.post<PubDocumentSearchResponse>(this.docApiRootForMcm, docSearchReq)
  }

  generateSearchRequest( searchTerm: string = localStorage.getItem("searchTerm") ? localStorage.getItem("searchTerm") : '',
                         sourceFilters?: string,
                         docFilters?: string,
                         pubFilters?:string): VizDocSearchRequest {


    const genericReq: VizDocSearchRequest = {
      "search": {
        "query" : searchTerm
      },
      "count":40,
      "facets":[
        {"name":"sourcetype"},
        {"name":"pubTitle"},
        {"name":"objectype"}
      ]
    }

    console.log("searchterm", searchTerm);
    genericReq.search = {
      "query": searchTerm,
      "fulltext": false,
      "publications": [],
      "filters":this.prepareFilters(sourceFilters, docFilters, pubFilters)
    }

    this.applyDateFilters(genericReq);
    this.applyFullTextLimiter(genericReq);
    return genericReq;
  }

  //ApplyFullTextLimiter
  private applyFullTextLimiter(searchReq: VizDocSearchRequest) {
    let limiter = JSON.parse(localStorage.getItem('fullTextLimiter')) === null ? false : JSON.parse(localStorage.getItem('fullTextLimiter'));
    searchReq.search.fulltext = limiter;
  }


  //applyDateFilters
  private applyDateFilters(searchReq: VizDocSearchRequest) {
    let fDate = JSON.parse(localStorage.getItem('fromDate'));
    let tDate = JSON.parse(localStorage.getItem('toDate'));

    if(fDate !=null && fDate.length>0)
      searchReq.search.startDate = new Date(fDate);

    if(tDate !=null && tDate.length>0)
    searchReq.search.endDate = new Date(tDate);

  }

  private storeSearchRequest(searchReq: VizDocSearchRequest) {
    localStorage.setItem('vsReq', JSON.stringify(searchReq));
  }

  reteriveSearchRequest(): VizDocSearchRequest | null {
    return JSON.parse(localStorage.getItem('vsReq'));
  }

  currentSearchTerm() {
    let docReq: VizDocSearchRequest = this.reteriveSearchRequest();
    return docReq!=null ? docReq.search.query : '';
  }

  clearSearchRequest() {
    localStorage.removeItem('sReq');
  }

  clearRefinementData() {
    this.clearFilters();
    this.clearSearchRequest();
  }
 
  private prepareFilters(sourceFilter?: string, docFilter?: string, pubTitle?:string): Filter[] {
    let filters = [];
    if(sourceFilter !=null && sourceFilter.length >0) {
      let sFilters =  JSON.parse(sourceFilter);
      if(sFilters!=null && sFilters.length>0) {
        let sf = {
          "name": "sourcetype",
          "entries": sFilters
        }
        filters.push(sf);
      }
    }  

    if(docFilter !=null && docFilter.length >0) {
      let sDocFilters =  JSON.parse(docFilter);
      if(sDocFilters!=null && sDocFilters.length>0) {
        let df = {
          "name": "objectype",
          "entries": sDocFilters
        }
        filters.push(df);

      }    
    }

    if(pubTitle !=null && pubTitle.length >0) {
      let sPubFilters =  JSON.parse(pubTitle);
      if(sPubFilters!=null && sPubFilters.length>0) {
        let pf = {
          "name": "pubTitle",
          "entries": sPubFilters
        }
        filters.push(pf);

      }    
    }

    return filters;
  }

  setDocCount(noOfHits: number) {
    this.docCountSubject.next(noOfHits);
  }

  setPubFacetSubject(entry: Entry[]) {
    this.pubFacetSubject.next(entry);
  }

  setSourceFacetSubject(entry: Entry[]) {
    this.sourceFacetSubject.next(entry);
  }

  setObjectFacetSubject(entry: Entry[]) {
    this.objectFacetSubject.next(entry);
  }

  setSourceFilterSubject(sourceFilters: string) {
    this.sourceTypeFilterSubject.next(sourceFilters);
  }

  setDocTypeFilterSubject(docTypeFilters: string) {
    this.docTypeFilterSubject.next(docTypeFilters);
  }

  setPubFilterSubject(pubFilters: string) {
    this.pubFilterSubject.next(pubFilters);
  }

  setDateFilterChange(b: boolean) {
    this.dateFilterSubject.next(true);
  }

  setFullTextLimiterChange(val: boolean) {
    this.fullTextLimiterSubject.next(val);
  }


  // getDatabaseSubscription(wbId: string) {
  //   //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
  //   let url = "/api/wbm/workbench/"+wbId+"/subscription/flat";

    

  //   return this.http.get<DbResponse>(url).pipe(
  //     tap( db => console.log( 'db==>',db)),
  //     shareReplay(1)
  //   );
  // }

  // getDatabaseSubscription2(wbId: string) {
  //   //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
  //   let url = "/api/wbm/workbench/"+wbId+"/subscription";

    

  //   return this.http.get<DbResponse>(url)
  // }

  getMonikerInfo(moniker: string) {
    let url = "/api/wbm/product/"+moniker;

    return this.http.get<Database>(url).pipe(
      tap( db => console.log ('getting moniker =>', db)),
      shareReplay(1)
    );
  }

  getPubFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'pubTitle');
  }

  getTotalPublications(facets: Facet[]) {
    return this.extractSubjectsFromFacets(facets, 'pubTitle').length;
  }

  getSourceFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'sourcetype');
  }

  getDocTypeFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'objectype');
  }

  private extractSubjectsFromFacets(facets: Facet[], facetType: string): Entry[] {
    if(facets !=null && facets.length >0) 
      return facets.find( facet => facet.name === facetType).entries;

    return []
  }

  clearFilters() {
    localStorage.removeItem('sourceFilters');
    localStorage.removeItem('docFilters');
    localStorage.removeItem('fromDate');
    localStorage.removeItem('toDate');
    localStorage.removeItem('fullTextLimiter');

  }

  setFilterChecked(checked: boolean) {
    this.displayFilterSubject.next(checked);
  }

  getSelectedPubIds(): Array<string> {
    return (localStorage.getItem('selectedPubs') === null ? []: JSON.parse(localStorage.getItem('selectedPubs')));
  }

  public displayClearFilters(): boolean | false {
    let sf = JSON.parse(localStorage.getItem('sourceFilters'));
    let df = JSON.parse(localStorage.getItem('docFilters'));
    let fromDate = JSON.parse(localStorage.getItem('fromDate'));
    let toDate = JSON.parse(localStorage.getItem('toDate'));
    let fullTextLimiter =  JSON.parse(localStorage.getItem('fullTextLimiter'))

    if( (sf!=null && sf.length >0) || 
        (df!=null && df.length >0) ||
        (fromDate!=null && fromDate.length>0) ||
        (toDate!=null && toDate.length>0) ||
        (fullTextLimiter === true)) {
      return true;
    }

  }

  public isDatesSelected(): boolean | false {
    let fromDate = JSON.parse(localStorage.getItem('fromDate'));
    let toDate = JSON.parse(localStorage.getItem('toDate'));
    if ((fromDate!=null && fromDate.length>0) ||
         (toDate!=null && toDate.length>0))
         return true; 

  }

  createProject(dataset:VizPreProject){
    const apiUrl = 'api/mcm/corpus';
    return this.http.post(apiUrl, dataset);
  }

  getProjects():Observable<VizCorpusResponse>{
    const apiUrl = 'api/mcm/corpus';
    return this.http.get(apiUrl);
  }

  getProjectsDetails(corpusId:string):Observable<VizProject>{
    const apiUrl = 'api/mcm/corpus/' + corpusId;
    return this.http.get(apiUrl);
  }

  deleteProject(corpusId:string):Observable<any>{
    const apiUrl = 'api/mcm/corpus/' + corpusId;
    return this.http.delete(apiUrl);
  }


  

 


}
