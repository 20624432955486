<header>
  <div  class="header_div" 
        fxLayout="row" 
        fxLayoutAlign="space-between center">

    <div class="header_left" 
         tabindex="0" 
         role="link" 
         (click)="goToHomePage()" 
         (keyup.enter)="goToHomePage()" 
         role="link">

      <img  class="tdm_logo"
            src=" ../../assets/images/TDM_logo.svg"
            title="Text Data Mining Studio Logo"/>

      <span class="title_span">
        <div class="proquest_logo">ProQuest</div>
        <div class="header_title" title="Text and Data Mining Studio">TDM Studio</div>
      </span>

    </div>

    <div  *ngIf="showDashboardLinks()" 
          fxLayout="row" 
          fxLayoutAlign="space-between center" 
          fxLayoutGap.md="25px"
          fxLayoutGap.lg="45px"
          fxLayoutGap.xl="55px"
          fxShow.lt-md="true"
          fxShow="true"
          role="tablist"
          aria-label="Dashboard Navigation" 
          class="links">
          
      <div  fxLayout="row" *ngIf="displayTopNavWorkbenchLink"
            fxLayoutAlign="start center" 
            fxShow.lt-md="{{isWorkbench}}"
            [ngClass]="isWorkbench ? 'selected site_nav' : 'site_nav'" 
            (click)="goToWorkbench()" 
            (keyup.enter)="goToWorkbench()"
            role="tab" 
            tabindex="0" 
            [attr.aria-selected]="isWorkbench">

        <img class="icon" 
            src="../../../assets/images/Flexible_new.svg" 
            alt="Workbench icon" 
            aria-hidden="true">

        <span>Workbench</span>
      </div>

      <!-- isRequest Access modal window-->
      <div  class="request-access-modal" 
            *ngIf="isRequestAccess" 
            fxLayout="column" 
            fxLayoutAlign="start stretch" 
            role="dialog" aria-modal="true" 
            aria-labelledby="request-header" 
            aria-describedby="request-content">

        <h1 class="modal-header" id="request-header">Activation Request</h1>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <p class="modal-content" id="request-content">Your institution can activate your workbench.
            Would you like to request activation?</p>
          <div class="button-bar" fxLayout="row" fxLayoutAlign="space-between center">
            <a href=" https://proquest.iad1.qualtrics.com/jfe/form/SV_9RZ51zyg0Ow92iW"><div class="request-button">Send Request</div></a>
            <div class="link" tabindex="0" (click)="isRequestAccess = false" (keyup.enter)="isRequestAccess = false" aria-label="cancel link">Cancel</div>
          </div>
        </div>
      </div>

      <div  *ngIf="displayTopNavVizLink"
            fxLayout="row" 
            fxLayoutAlign="start center" 
            fxShow.lt-md="{{isAnalysis}}"
            [ngClass]="isAnalysis ? 'selected site_nav' : 'site_nav'" 
            (click)="goToAnalysis()" (keyup.enter)="goToAnalysis()"
            role="tab" 
            tabindex="0" 
            [attr.aria-selected]="isAnalysis">

        <img class="icon" src="../../../assets/images/Viz-icon.svg" alt="Visualization icon" aria-hidden="true">
        <span>Visualization</span>
      </div>
      <div class="help-div" tabindex="0" *ngIf="isAnalysis || isWorkbench" 
          (click)="openHelpWindow()"
          (keyup.enter)="openHelpWindow()"
          fxShow.lt-md="false">
        <span class="bi-question-circle"></span>
        <span class="help-learn">Help & Learn</span>
      </div>
    </div>

    <!-- Right panel if user is not logged in  -->
    <div  *ngIf="!isUser" class="header_right">

      <div  class="login"
            tabindex="0"
            (click)="auth.login()"
            (keyup.enter)="auth.login()" role="button">Log In</div>

      <a href="/createaccount" class="create-account" rel="noopener">
        Create Account
      </a>

      <a  class="login"
          [tdmsExternalLink]=" 'https://proquest.iad1.qualtrics.com/jfe/form/SV_9Mk2ZyzryrQBsRE' ">Free Trial</a>
    </div>

    <!-- Right panel if user is logged in and screen is Bigger -->
     <div *ngIf="isUser" 
          class="user"
          fxShow.lt-md="false"
          fxShow="true">
      
        <div  class="logout" 
              (click)="auth.logout()"  
              (keyup.enter)="auth.logout()" 
              tabindex="0"
              role="button">Logout
        </div>

        <div class="account_info">
          <p class="email">{{ tdmUser?.email }}</p>
        </div>
    </div>

    <!-- Right panel if user is logged in and screen is small -->
    <div  *ngIf="isUser" 
          class="user"
          fxShow.lt-md="true"
          fxShow="false"
          fxLayout="row"
          fxLayoutAlign="space-between">

      <div class="account_info_sm">
        <p class="email">{{ tdmUser?.email }}</p>
      </div>

      <div class="menu" (click)="isDropdownMenu = !isDropdownMenu" (keyup.enter)="isDropdownMenu = !isDropdownMenu" tabindex="0">
        <div class="menu_bar"></div>
        <div class="menu_bar"></div>
        <div class="menu_bar"></div>
      </div>

      <div class="menu_dropdown"
            *ngIf="isDropdownMenu"
            fxLayout="column"
            fxLayoutGap="5px">

        <div fxLayout="row" *ngIf="displayTopNavWorkbenchLink"
            fxLayoutAlign="start center" 
            fxShow.lt-md="{{!isWorkbench}}"
            [ngClass]="isWorkbench ? 'selected site_nav' : 'site_nav'" 
            (click)="goToWorkbench()" 
            (keyup.enter)="goToWorkbench()" tabindex="0">

            <img class="icon" src="../../../assets/images/Flexible_new.svg" alt="Workbench icon">
            <span>Workbench</span>
        </div>

        <div   *ngIf="displayTopNavVizLink"
              fxLayout="row" 
              fxLayoutAlign="start center" 
              fxShow.lt-md="{{!isAnalysis}}"
              [ngClass]="isAnalysis ? 'selected site_nav' : 'site_nav'" 
              (click)="goToAnalysis()" 
              (keyup.enter)="goToAnalysis()" tabindex="0">

          <img class="icon" src="../../../assets/images/Viz-icon.svg" alt="Visualization icon">
          <span>Visualization</span>
        </div>

        <div class="help-div" tabindex="0" *ngIf="isAnalysis || isWorkbench" 
            (click)="openHelpWindow()"
            (keyup.enter)="openHelpWindow()">
            <span class="bi-question-circle"></span>
            <span class="help-learn">Help & Learn</span>
        </div>        

        <div class="site_nav" (click)="auth.logout()" (keyup.enter)="auth.logout()" tabindex="0">Logout</div>

        <!-- <div *ngIf="isHelp" class="site_nav" (click)="openDocumentation()" (keyup.enter)="openDocumentation()" tabindex="0">
          Help
          <div class="help_sm">V</div>
        </div> -->
      </div>
    </div>
  </div>
</header>

<div>
	<tdms-onbording-banner (closeOnBoard)="onCloseOnBoardEvent($event)" *ngIf="displayOnboard"></tdms-onbording-banner>
</div>