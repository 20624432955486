import { BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { WorkbenchResponse, UserInfo, WbStatus, WbAction, WbUserType } from 'src/app/workbenchDashboard/workbench.model';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WorkbenchDataService {
    constructor(http) {
        this.http = http;
        this.workbenchIdSource = new BehaviorSubject('default workbenchId');
        this.workbenchSelectionSource = new BehaviorSubject('default workbenchId');
        this.currentWorkbenchId$ = this.workbenchIdSource.asObservable();
        this.currentlySelectedWorkbench = this.workbenchSelectionSource.asObservable();
        this.apiRootForWb = '/api/wb';
        this.apiRootForUm = '/api/um';
        this.collaboratorTestData = [
            {
                'wbId': 'devlt10',
                'hasWos': true,
                'isCollaborator': true
            },
            {
                'wbId': 'devlt7',
                'hasWos': false,
                'isCollaborator': true
            },
            {
                'wbId': 'devlt15',
                'hasWos': true,
                'isCollaborator': false
            },
            {
                'wbId': 'devlt1',
                'hasWos': true,
                'isCollaborator': true
            }
        ];
    }
    newWorkbenchId(newWorkbenchId) {
        this.workbenchId = newWorkbenchId;
        this.workbenchSelectionSource.next(newWorkbenchId);
        localStorage.setItem('workbench', newWorkbenchId);
    }
    changeWorkbenchId() {
        this.workbenchIdSource.next(this.workbenchId);
    }
    getWorkbenches() {
        console.log('getWorkbenches ....>');
        const apiURL = 'api/wbm/workbench';
        console.log('apiUrL =', apiURL);
        return this.http.get(apiURL);
    }
    getWorkbenchById(wbId) {
        const apiURL = 'api/wbm/workbench/' + wbId;
        return this.http.get(apiURL);
    }
    getAdminWorkbenches(count, offset) {
        let params = new HttpParams();
        params = params.append('count', count.toString());
        params = params.append('offset', offset.toString());
        const apiURL = 'api/wbm/admin/workbench';
        return this.http.get(apiURL, { params: params });
    }
    getAdminWorkbenchUsers(wbId) {
        const apiURL = 'api/wbm/admin/users?workbenchId=' + wbId;
        return this.http.get(apiURL);
    }
    createUser(user) {
        const apiUrl = this.apiRootForUm + "/admin/user";
        return this.http.post(apiUrl, user);
    }
    addUserToWorkbench(userId, wbId) {
        const apiUrl = 'api/wbm/admin/workbench/' + wbId + '/user/' + userId;
        return this.http.post(apiUrl, null);
    }
    deleteUserFromWorkbench(userId, wbId) {
        const apiUrl = 'api/wbm/admin/workbench/' + wbId + '/user/' + userId;
        return this.http.delete(apiUrl);
    }
    getUserInfo() {
        console.log('getUserInfo ....>');
        const apiURL = '/api/um/user';
        console.log('getUserInfo apiUrl ==>', apiURL);
        return this.http.get(apiURL);
    }
    getWorkbenchLink(workbenchId) {
        console.log('getWorkbencheLink ....>');
        const apiURL = 'api/wbm/workbench/' + workbenchId + '/accesslink/';
        console.log('apiUrL =', apiURL);
        return this.http.get(apiURL, { responseType: 'text' });
    }
    getWorkbenchStatusById(workbenchId) {
        const apiURL = 'api/wbm/workbench/' + workbenchId + '/status';
        return this.http.get(apiURL);
    }
    updateWbStatusById(workbenchId, wbAction) {
        const apiURL = 'api/wbm/workbench/' + workbenchId + '/status';
        return this.http.post(apiURL, wbAction);
    }
    getDataset(datasetId) {
        const apiUrl = 'api/cm/corpus/' + datasetId;
        return this.http.get(apiUrl);
    }
    getDatasetList() {
        const apiUrl = '/api/cm/corpus?workbenchId=' + this.workbenchId;
        return this.http.get(apiUrl);
    }
    deleteDataset(datasetId) {
        const apiUrl = '/api/cm/corpus/' + datasetId;
        return this.http.delete(apiUrl, { responseType: 'text' });
    }
    getGisStatus(wbId) {
        const apiUrl = '/api/wbm/workbench/' + wbId + '/subscription/gis';
        return this.http.get(apiUrl);
    }
    getWosSubscriptionStatus(wbId) {
        const apiUrl = '/api/wbm/workbench/' + wbId + '/subscription/wos';
        return this.http.get(apiUrl);
    }
    hasAccessToWos(wbId) {
        const subscriptionAPiUrl = '/api/wbm/workbench/' + wbId + '/subscription/wos';
        const wbUserTypeApiUrl = '/api/wbm/workbench/' + wbId + '/user/type';
        const wosSubscriptionInfo$ = this.http.get(subscriptionAPiUrl);
        const wbUserTypes$ = this.http.get(wbUserTypeApiUrl);
        return forkJoin([wosSubscriptionInfo$, wbUserTypes$])
            .pipe(map(([wosSubcriptionRes, userTypesRes]) => {
            console.log(' => wosSubcriptionRes =', JSON.stringify(wosSubcriptionRes));
            console.log(' => userTypesRes =', JSON.stringify(userTypesRes));
            let hasWos = wosSubcriptionRes.hasWos;
            let collaboratorItem = userTypesRes.userTypes.find(item => {
                if (item.type === 'collaborator')
                    return item;
            });
            if (hasWos && collaboratorItem.status === 'false')
                return true;
            return false;
            //return this.simulateCollaboratorWithMockData(wbId); //For Testing only...
        }));
    }
    simulateCollaboratorWithMockData(wbId) {
        console.log('simulating collaborator with test data ==> ', JSON.stringify(this.collaboratorTestData));
        let { hasWos, isCollaborator } = this.collaboratorTestData.find((wbInfo) => wbInfo.wbId === wbId);
        if (!hasWos || (hasWos && isCollaborator))
            return false;
        return true;
    }
    getMetadataExportLimitTest(wbId, trialOrSub) {
        const apiURL = '/api/cm/metadataexport/' + wbId + '/exportLimit';
        return this.http.get(apiURL)
            .pipe(catchError(error => {
            console.error("Ann error occurred:", error);
            if (trialOrSub === 'SubReached') {
                return this.getSubLimitReached();
            }
            else if (trialOrSub === 'TrialReached') {
                return this.getTrialLimitReached();
            }
            else if (trialOrSub === 'TrialRemaining') {
                return this.getTrialLimit();
            }
            else {
                return this.getSubLimit();
            }
        }));
    }
    getMetadataExportLimit(wbId, trialOrSub) {
        const apiURL = '/api/cm/metadataexport/' + wbId + '/exportLimit';
        return this.http.get(apiURL)
            .pipe(catchError(error => {
            console.error("Ann error occurred:", error);
            const errorObj = {
                status: error.status,
                statusText: error.statusText,
                message: 'An error occurred while getting export limit...'
            };
            return throwError(errorObj);
        }));
    }
    getSubLimit() {
        return of({
            "subscriptionType": "CUSTOMER",
            "metadataRowsRemaining": 2000000,
            "metadataRowsWeeklyLimit": 5000000
        });
    }
    getSubLimitReached() {
        return of({
            "subscriptionType": "CUSTOMER",
            "metadataRowsRemaining": 0,
            "metadataRowsWeeklyLimit": 5000000
        });
    }
    getTrialLimit() {
        return of({
            "subscriptionType": "TRIAL",
            "metadataRowsRemaining": 231,
            "metadataRowsWeeklyLimit": 10000
        });
    }
    getTrialLimitReached() {
        return of({
            "subscriptionType": "TRIAL",
            "metadataRowsRemaining": 0,
            "metadataRowsWeeklyLimit": 10000
        });
    }
    getExportMetadataLink(corpusid, exportType) {
        const apiUrl = '/api/cm/corpus/' + corpusid + '/docmetadata/' + exportType + '.csv';
        return this.http.get(apiUrl);
    }
    getExportWOSLink(corpusid) {
        const apiUrl = '/api/cm/corpus/wos/' + corpusid + '/export';
        return this.http.get(apiUrl);
    }
    /**
     * This method helps to identify the userType (collaborator or not)
     * @param wbId
     * @returns WbUserType[]
     */
    getWbUserTypes(wbId) {
        const apiUrl = '/api/wbm/workbench/' + wbId + '/user/type';
        return this.http.get(apiUrl);
    }
}
WorkbenchDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkbenchDataService_Factory() { return new WorkbenchDataService(i0.ɵɵinject(i1.HttpClient)); }, token: WorkbenchDataService, providedIn: "root" });
