<div>
	<mat-spinner [diameter]="20" [strokeWidth]="3" class="spinner-container" *ngIf="isLoading"></mat-spinner>
	<div class="error-info" *ngIf="errMsg"> {{ errMsg.error }}</div>
</div>

<div class="dashboard-wrapper" *ngIf="!isLoading && errMsg == null">
    <div class="container">
        <div class="tour_bar">
            <div class="title">Get Started with TDM Studio</div>
            <div class="text">Use interactive data visualizations to uncover patterns and connections in content from newspapers, dissertations, and theses.</div>
            <!-- <a href='/analysis/dashboard' class="link"><div class="tour_button" [ngClass]="{shadow: isHover}" (mouseover)="isHover = true" (mouseout)="isHover = false">Take a tour of Geographic Analysis</div></a> -->
        </div>
    </div>

    <div class='project_toggle'>
        <div class='toggle'>
            <div [class] = "!isSampleProject ? 'toggle_on' : 'toggle_off'" (click)="toggleProjects(false)">
                Your Projects
                <div class='right_underline' *ngIf="!isSampleProject"></div>
            </div>
        </div>
    </div>

    <tdms-analysis-sample-projects *ngIf="isSampleProject"></tdms-analysis-sample-projects>
    <tdms-analysis-projects *ngIf="!isSampleProject"></tdms-analysis-projects>
</div>
