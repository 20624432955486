
<tdms-create-project-header></tdms-create-project-header>
<tdms-create-project-progress-bar stage='refine'></tdms-create-project-progress-bar>

<div class="page">
    <div class="search_bar">
      <div class="search_div">
          <input #searchBar type="text" 
                            class="search" 
                            placeholder="Enter search here…" 
                            [formControl]="searchField"
                            (keyup.enter)="search(searchField.value)"/>
          <span *ngIf="searchBar.value !== null && searchBar.value.length !== 0" class ='clear' (click)="search(''); searchBar.value = ''">n</span>                  
      </div>
        <div class="search_button" (click)="search(searchField.value)" >;</div>
  </div>
  <div *ngIf="docresults$ | async as obsRes">
    <div *ngIf="obsRes.type !== 'start' && !isDocs(obsRes.searchResp?.docsFound)" class="alert alert-danger">
      <p class="warning_icon">w</p>
      Your search found 0 results. Please modify your search query, date ranges, or search filters, and try again.
    </div>
  </div>
  
</div>

<div *ngIf="docresults$ | async as obsRes">
  
  <div class="container" fxLayout="row"
                        fxLayoutGap="15px"
                        fxLayoutAlign="start top"
                        fxLayout.xm="column">
      <div class="filters" fxFlex="25" fxLayout="column" *ngIf="obsRes.type === 'finish'">

        <tdms-docfacet [pubFacets]=pubFacets(obsRes.searchResp?.facets)
                       [sourceFacets]=sourceFacets(obsRes.searchResp?.facets)
                       [docFacets]=docFacets(obsRes.searchResp?.facets)
                       [isAnalysis]=true
                       [stickyHeight]="getFooterHeight(obsRes.searchResp?.docsFound)"
                       (isApplyFilterTriggered)='applyFilters(searchField.value)'></tdms-docfacet>

      </div>
      <div class="results" fxFlex="75">
        <ng-template [ngIf]="obsRes.type === 'finish'">
          <tdms-publication-results [results]= obsRes.searchResp?.hits [docCount] = obsRes.searchResp?.docsFound></tdms-publication-results>
        </ng-template>  
        
        <div class="loading-container" *ngIf="obsRes.type === 'start'">
          <div class="center">
            <ng-template [ngIf]="obsRes.type === 'start'" ><mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner></ng-template>
          </div>
        </div>
        

      </div>
  </div>


  <tdms-dataset-selection-footer 
    *ngIf="obsRes.type === 'finish'"
    [noOfItemsSelected]=obsRes.searchResp?.docsFound
    selectionLabel= "Document"
    select= ""
    btnLabel="Next: Review project"
    isRefineContent="true"
    nextStep="VizCreateDataset"
    docLimit="10000">
  </tdms-dataset-selection-footer>
</div>  

