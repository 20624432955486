import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnalysisAccountInfo, AnalysisAccountResponse, AnalysisUser } from './models/account.model';
import { Observable, Subject } from 'rxjs';
import { AnalysisUserInfo, User, UserInfo } from '../workbenchDashboard/workbench.model';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  private cardSelectionSubject = new Subject<boolean>();
  selectViz$ = this.cardSelectionSubject.asObservable();

  userApiRootForMm = "api/mum/";

  constructor(private http: HttpClient) { }

  getUserAccounts(email:string): Observable<AnalysisAccountResponse> {

    const apiUrl = this.userApiRootForMm + 'accounts?email='+encodeURIComponent(email);
    return this.http.get(apiUrl);
  }

  getAccount(acctId:string): Observable<any> {
    const apiUrl = this.userApiRootForMm + 'accounts/' + acctId + '/access';
    return this.http.get(apiUrl);
  }

  getUserStatus(email:string): Observable<any> {
    const apiUrl = this.userApiRootForMm + 'user/' + encodeURIComponent(email) + '/status'
    return this.http.get(apiUrl);
  }

  ///api/um/user
  getAnalysisUserInfo(email?:string): Observable<UserInfo> {
    //const apiUrl = this.userApiRootForMm + 'user/' + email + '/status'
    const apiUrl = this.userApiRootForMm + 'user'
    return this.http.get<UserInfo>(apiUrl);
  }


  getDashboardLink(): string {
    let tdmUser: User = <User>JSON.parse(localStorage.getItem('user'));
    let redirect ='/home';
    if(tdmUser && tdmUser.isAnalysisUser && tdmUser.isStudioUser) {
      redirect ='/workbenchdashboard'
    } else if(tdmUser && tdmUser.isStudioUser) {
      redirect ='/workbenchdashboard'
    } else if(tdmUser && tdmUser.isAnalysisUser) {
      redirect = '/analysis/dashboard';
    }
    return redirect;
  }



  setVizCardSelection(selection: boolean) {
    this.cardSelectionSubject.next(selection);
  }

}
