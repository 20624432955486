import { Injectable } from '@angular/core';
import { PubSearchRequest, PublicationResponse, Database, DbResponse } from 'src/app/_shared/models/publication-search.model';
import { BehaviorSubject, Observable, Subject, throwError, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PubDocumentSearchResponse, DocSearchRequest, Entry, Facet, Filter, PubFacetResponseType } from 'src/app/_shared/models/publication-results.model';
import { tap, shareReplay, toArray, map } from 'rxjs/operators';
import { DefaultDataset, TopSearchResponse } from 'src/app/shared/models/dataset.model';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  docApiRootForCm = '/api/cm/document/search';
  wosApiDocSearch = '/api/cm/document/search/wos';
  pubApiRootForCm = '/api/cm/publication/search';

  constructor(private http: HttpClient) { }

  private docCountSubject = new Subject<number>();
  totalDocCount$ = this.docCountSubject.asObservable();

  private searchRequestSubject = new Subject<DocSearchRequest>();
  searchRequest$ = this.searchRequestSubject.asObservable();

  //Build Facets stream
  private pubFacetSubject = new Subject<Entry[]>();
  pubFacet$ = this.pubFacetSubject.asObservable();

  private sourceFacetSubject = new Subject<Entry[]>();
  sourceFacet$ = this.sourceFacetSubject.asObservable();

  private objectFacetSubject = new Subject<Entry[]>();
  objectFacet$ = this.objectFacetSubject.asObservable();


  //Build Filters --> for search Request --> Filters in transforme form i.e., {"searchValue": "source Journal"}
  private pubFilterSubject = new BehaviorSubject<string>('');
  pubFilter$ = this.pubFilterSubject.asObservable();

  private docTypeFilterSubject = new BehaviorSubject<string>('');
  docTypeFilter$ = this.docTypeFilterSubject.asObservable();

  private sourceTypeFilterSubject = new BehaviorSubject<string>('');
  sourceTypeFilter$ = this.sourceTypeFilterSubject.asObservable();

   private dbFilterSubject = new BehaviorSubject<string>('');
   dbFilter$ = this.dbFilterSubject.asObservable();

   private dbProductSubject = new BehaviorSubject<string>('');
   dbProduct$ = this.dbProductSubject.asObservable();

   //Define subject for user currently selected filters.
   private currSelectedObjectTypesSubject = new BehaviorSubject<Array<string>>([]);
   currSelectedObjectTypes$ = this.currSelectedObjectTypesSubject.asObservable();

  private currSelectedSourceTypesSubject = new BehaviorSubject<Array<string>>([]);
  currSelectedSourceTpes$ = this.currSelectedSourceTypesSubject.asObservable();

  private dateFilterSubject = new Subject<boolean>();
  dateFilter$ = this.dateFilterSubject.asObservable();

  private fullTextLimiterSubject = new BehaviorSubject<boolean>(false);
  fullTextLimitter$ = this.fullTextLimiterSubject.asObservable();

  //DisplayFilter Subject  (button)
  private displayFilterSubject = new BehaviorSubject<boolean>(false);
  displayFilter$ = this.displayFilterSubject.asObservable();

  private pubListUpdatedSubject = new BehaviorSubject<boolean>(false);
  pubListUpdated$ = this.pubListUpdatedSubject.asObservable();

  private dateRangeSubject = new BehaviorSubject<string>('');
  dateRangeSubject$ = this.dateRangeSubject.asObservable();

  private lazyPubFacetSubject = new Subject<Facet[]>();
  lazyPubFacetSubject$ = this.lazyPubFacetSubject.asObservable();


  private lazyPubFacetSubjectV1 = new Subject<PubFacetResponseType>();
  lazyPubFacetSubjectV1$ = this.lazyPubFacetSubjectV1.asObservable();


  private handleError(err: any) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      //network error
      errorMessage = `An error occurred: ${err.error.errorMessage}`;
    } else {
      //backend error
      errorMessage = `Error occurred: (${err.status}: ${err.body.error})`;
    }
    console.error(err);
    return throwError(errorMessage);

    return null;
  }

  public setSearchRequest(req: DocSearchRequest) {
    console.log("req => ", req);
    this.searchRequestSubject.next(req);
  }

  search(docSearchReq: DocSearchRequest): Observable<PubDocumentSearchResponse> {
    //store request into local store
    this.storeSearchRequest(docSearchReq);
    return this.http.post<PubDocumentSearchResponse>(this.docApiRootForCm, docSearchReq)
  }

  /**
   * This method is used to get the PubFacets
   * @param docSearchReq 
   * @returns 
   */
  searchForPubFacets(docSearchReq: DocSearchRequest): Observable<PubDocumentSearchResponse> {
    //store request into local store
    this.storeSearchRequest(docSearchReq);
    return this.http.post<PubDocumentSearchResponse>(this.docApiRootForCm, docSearchReq)
  }



  wosSearch(docSearchReq: DocSearchRequest): Observable<PubDocumentSearchResponse> {
    //store request into local store
    this.storeWosSearchRequest(docSearchReq);
    return this.http.post<PubDocumentSearchResponse>(this.wosApiDocSearch, docSearchReq)
  }
  //wosDocSearch

  /**
   * This method generates the searchRequest payload used for multiple API endpoints. 
   * 1. DocSearchRequest for Ascending
   * 2. DocSearchRequest for Descending
   * 3. DocSearchRequest only get the PubFacet information (Lazy Loading...)
   * @reqType parameter allFAceets or pubFacet.
   * 
   * @param searchTerm 
   * @param wbId 
   * @param publications 
   * @param dbs 
   * @param sortOrder 
   * @param sourceFilters 
   * @param docFilters 
   * @param dbFilters 
   * @param reqType 
   * @returns 
   */
  generateSearchRequest( searchTerm: string = '',
                         wbId: string,
                         sortOrder: string,
                         reqType: string = 'allFacets' ): DocSearchRequest {

    let isDatabase: boolean = localStorage.getItem('datasetType') === 'Database' ? true : false;
    const allFacetsProp = [{"name":"sourcetype"}, {"name":"objectype"}];
    const pubFacetsProp = [{"name":"pubTitle"}];

    const genericReq: DocSearchRequest = {
      "workbenchId": wbId,
      "search": {
        "query": searchTerm
      },
      "count": (reqType === 'allFacets') ? 40 : 0,
      "sortOrder": sortOrder,
      "facets": (reqType === 'allFacets') ? allFacetsProp : pubFacetsProp
    }
      genericReq.search = {
        "query": searchTerm,
        "fulltext": false,
        "publications": this.preparePublicationFilters(),
        "products": this.prepareDatabaseFilters(), 
        "filters":this.prepareFiltersV1()
      }
    
    this.applyDateFilters(genericReq);
    this.applyFullTextLimiter(genericReq);
    return genericReq;
  }



  generateWosSearchRequest(searchTerm: string = '',
    wbId: string,
    sortOrder: string,
    sourceFilters?: string,
    docFilters?: string,
    pubFilters?: string): DocSearchRequest {

    console.log(" Inside WOS SearchRequest pubFilters >>>>", JSON.stringify(pubFilters) );
    const genericReq: DocSearchRequest = {
      "workbenchId": wbId,
      "search": {
        "query": searchTerm
      },
      "count": 40,
      "sortOrder": sortOrder,
      "facets": [
        { "name": "sourcetype" },
        { "name": "pubTitle" },
        { "name": "objectype" }
      ]
    }

    console.log("preparing filers string WOS >>>>", this.prepareFilters(sourceFilters, docFilters, pubFilters));
      genericReq.search = {
        "query": searchTerm,
        "fulltext": false,
        "products": [{"moniker": "tdmwos", "name": "TDM WoS"}],
        "filters": this.prepareFilters(sourceFilters, docFilters, pubFilters)
      }
    
    this.applyDateFilters(genericReq);
    return genericReq;
}

  //ApplyFullTextLimiter
  private applyFullTextLimiter(searchReq: DocSearchRequest) {
    let limiter = JSON.parse(localStorage.getItem('fullTextLimiter')) === null ? false : JSON.parse(localStorage.getItem('fullTextLimiter'));
    searchReq.search.fulltext = limiter;
  }


  //applyDateFilters
  private applyDateFilters(searchReq: DocSearchRequest) {
    let fDate = JSON.parse(localStorage.getItem('fromDate'));
    let tDate = JSON.parse(localStorage.getItem('toDate'));

    if (fDate != null && fDate.length > 0)
      searchReq.search.startDate = new Date(fDate);

    if (tDate != null && tDate.length > 0)
      searchReq.search.endDate = new Date(tDate);

  }

  private storeSearchRequest(searchReq: DocSearchRequest) {
    localStorage.setItem('sReq', JSON.stringify(searchReq));
  }

  private storeWosSearchRequest(searchReq: DocSearchRequest) {
    localStorage.setItem('sReqWos', JSON.stringify(searchReq));
  }

  reteriveSearchRequest(): DocSearchRequest | null {
    return JSON.parse(localStorage.getItem('sReq'));
  }

  currentSearchTerm() {
    let docReq: DocSearchRequest = this.reteriveSearchRequest();
    if(docReq === null) {
      let defaultSearchTerm = localStorage.getItem('searchValue');
      //localStorage.removeItem('searchValue');
      return defaultSearchTerm;
    }
    return docReq!=null ? docReq.search.query : '';
  }

  clearSearchRequest() {
    localStorage.removeItem('sReq');
  }

  clearRefinementData() {
    this.clearFilters();
    this.clearSearchRequest();
  }

  private prepareFilters(sourceFilter?: string, docFilter?: string, pubFilter?:string, dbFilter?: string): Filter[] {
    let filters = [];

    if(pubFilter !=null && pubFilter.length >0) {
      let pFilters =  JSON.parse(pubFilter);
      if(pFilters!=null && pFilters.length>0) {
        let pf = {
          "name": "pubTitle",
          "entries": pFilters
        }
        filters.push(pf);
      }
    }

    if(sourceFilter !=null && sourceFilter.length >0) {
      let sFilters =  JSON.parse(sourceFilter);
      if(sFilters!=null && sFilters.length>0) {
        let sf = {
          "name": "sourcetype",
          "entries": sFilters
        }
        filters.push(sf);
      }
    }

    if (docFilter != null && docFilter.length > 0) {
      let sDocFilters = JSON.parse(docFilter);
      if (sDocFilters != null && sDocFilters.length > 0) {
        let df = {
          "name": "objectype",
          "entries": sDocFilters
        }
        filters.push(df);

      }
    }

    if(dbFilter !=null && dbFilter.length >0) {
      let dbFilters =  JSON.parse(dbFilter);
      if(dbFilters!=null && dbFilters.length>0) {
        let df = {
          "name": "bundles",
          "entries": dbFilters
        }
        filters.push(df);

      }
    }
    return filters;
  }

  private prepareDatabaseFilters(): any[] {

    let products = []

    //DbFilter...
    from(this.dbFilterValues())
    .pipe(
        map(val  => ({"moniker": val.moniker, "name":val.name})),
        tap(val => console.log(val)),
        toArray(),
      ).subscribe(val => {
        products = val;
      })  

      return products;

  }

  private preparePublicationFilters(): any[] {
    let publications: any[]= [];
    from(this.pubFilterValues())
    .pipe(
        map(val => ({"searchValue": val.id})),
        toArray()
      ).subscribe(val =>  {
        publications =  val;
      })    

      return publications;
  }


  private prepareFiltersV1(): Filter[] {
    //sourceFilter?: string, docFilter?: string, pubFilter?:string, dbFilter?: string
    let pubFilter, dbFilter, docFilter, sourceFilter = '';
    let filters = [];

    //SourceFilters... (sourceType)
    from(this.sourceFilterValues())
    .pipe(
        map(val => ({"searchValue": val})),
        tap(val => console.log(val)),
        toArray(),
        map(val => JSON.stringify(val)),
        tap( val => console.log(val))
      ).subscribe(val => {
        sourceFilter =  val;
      })

    if(sourceFilter !=null && sourceFilter.length >0) {
      let sFilters =  JSON.parse(sourceFilter);
      if(sFilters!=null && sFilters.length>0) {
        let sf = {
          "name": "sourcetype",
          "entries": sFilters
        }
        filters.push(sf);
      }
    }

    //DocFilters... (objectType)
    from(this.docFilterValues())
    .pipe(
      map(val => ({"searchValue": val})),
      tap(val => console.log(val)),
      toArray(),
      map(val => JSON.stringify(val)),
      tap( val => console.log(val))
    ).subscribe(val =>  {
      docFilter =  val;
    })

    if (docFilter != null && docFilter.length > 0) {
      let sDocFilters = JSON.parse(docFilter);
      if (sDocFilters != null && sDocFilters.length > 0) {
        let df = {
          "name": "objectype",
          "entries": sDocFilters
        }
        filters.push(df);

      }
    }

    return filters;
  }

  private pubFilterValues = () => {
    let val = (JSON.parse(localStorage.getItem('pubFilters')) as {id: string, title: string}[]);
    if(val !=null && val.length >0) {
      return val;
    } else {
       return [];  
    }
     
  }

  private sourceFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('sourceFilters'));
    if(val !=null && val.length >0) 
      return val;
    else
       return [];  
    
  }

  private dbFilterValues = ():any[] => {
    let val = JSON.parse(localStorage.getItem('dbFilters'));
    if(val !=null && val.length >0) 
      return val;
    else
       return [];  
    
  }

  private docFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('docFilters'));
    if(val !=null && val.length >0) {
      return val;
    } else {
       return [];  
    }
     
  }

  setDocCount(noOfHits: number) {
    this.docCountSubject.next(noOfHits);
  }

  setPubFacetSubject(entry: Entry[]) {
    this.pubFacetSubject.next(entry);
  }
  
  setLazyPubFacetSubject(facets: Facet[]) {
    console.log("LazyPubFacetSubject >>>> ", JSON.stringify(facets));
    this.lazyPubFacetSubject.next(facets);

  }

  setLazyPubFacetSubjectV1(pubFacetRespType: PubFacetResponseType) {
    console.log("LazyPubFacetSubject from [-refinement.service.ts] >>>> ", JSON.stringify(pubFacetRespType));
    this.lazyPubFacetSubjectV1.next(pubFacetRespType);

  }

  setSourceFacetSubject(entry: Entry[]) {
    this.sourceFacetSubject.next(entry);
  }

  setObjectFacetSubject(entry: Entry[]) {
    this.objectFacetSubject.next(entry);
  }

  setSourceFilterSubject(sourceFilters: string) {
    this.sourceTypeFilterSubject.next(sourceFilters);
  }

  setDbFilterSubject(dbFilters: string) {
    this.dbFilterSubject.next(dbFilters);
  }

  setDbProductSubject(dbFilters: string) {
    this.dbProductSubject.next(dbFilters);
  }

  setDocTypeFilterSubject(docTypeFilters: string) {
    this.docTypeFilterSubject.next(docTypeFilters);
  }

  setPubFilterSubject(pubFilters: string) {
    this.pubFilterSubject.next(pubFilters);
  }

  setDateFilterChange(b: boolean) {
    this.dateFilterSubject.next(true);
  }

  setFullTextLimiterChange(val: boolean) {
    this.fullTextLimiterSubject.next(val);
  }

  setPubListUpdated(updated: boolean) {
    this.pubListUpdatedSubject.next(updated);
  }

  setDateRangeSubject(dateRange: string) {
    this.dateRangeSubject.next(dateRange);
  }

  getPublications(filter: string, wbId: string, pageSize: number, offset: number) {
    var pubRequest: PubSearchRequest = {
      workbenchId: wbId,
      search: { query: filter },
      count: pageSize,
      offset: offset
    }

    const apiURL = this.pubApiRootForCm;

    return this.http.post<PublicationResponse>(apiURL, pubRequest).pipe(shareReplay(2));
  }

  getTopSearches(wbId: string):Observable<TopSearchResponse>{
    let url = "/api/wbm/workbench/" + wbId + "/databases/popular"
    return this.http.get<TopSearchResponse>(url);
  }

  getDatabaseSubscription(wbId: string) {
    //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
    let url = "/api/wbm/workbench/" + wbId + "/subscription/flat";



    return this.http.get<DbResponse>(url).pipe(
      tap(db => console.log('db==>', db)),
      shareReplay(1)
    );
  }

  getDatabaseSubscription2(wbId: string) {
    //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
    let url = "/api/wbm/workbench/" + wbId + "/subscription";



    return this.http.get<DbResponse>(url)
  }

  getGisSubscription(wbId: string) {
    let url = "/api/wbm/workbench/" + wbId + "/subscription/gis";
    return this.http.get<DbResponse>(url)
  }

  getMonikerInfo(moniker: string) {
    let url = "/api/wbm/product/" + moniker;

    return this.http.get<Database>(url).pipe(
      tap(db => console.log('getting moniker =>', db)),
      shareReplay(1)
    );
  }

  getGisInfo(gisList: Database[]): Database[] {
    let gisInfoList = [];
    for (let gis of gisList) {
      switch (gis.moniker) {
        case "hearingsa": {
          gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 1824-1979; the module also includes unpublished hearings from the U.S. House (1832-1971) and Senate (1824-1979)."
          break;
        }
        case "hearingsb": {
          gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 1980-2003; the module also includes three collections of unpublished hearings from the U.S. Senate from 1980-1984."
          break;
        }
        case "hearingsc": {
          gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 2004-2010."
          break;
        }
        case "hearingsd2011": {
          gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings released by GPO and collected by ProQuest in 2011."
          break;
        }
      }
    }
    return gisList;
  }

  getPubFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'pubTitle');
  }

  getTotalPublications(facets: Facet[]) {
    return this.extractSubjectsFromFacets(facets, 'pubTitle').length;
  }

  getSourceFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'sourcetype');
  }

  getDocTypeFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'objectype');
  }

  getDbFacets(facets: Facet[]): Entry[] {
    return this.extractSubjectsFromFacets(facets, 'databases');
  }


  private extractSubjectsFromFacets(facets: Facet[], facetType: string): Entry[] {
    if(facets !=null && facets.length >0)
      return facets.find( facet => facet.name === facetType) ? facets.find( facet => facet.name === facetType).entries : [];

    return []
  }

  clearFilters() {
    localStorage.removeItem('sourceFilters');
    localStorage.removeItem('docFilters');
    localStorage.removeItem('pubFilters');
    localStorage.removeItem('fromDate');
    localStorage.removeItem('toDate');
    localStorage.removeItem('fDate');
    localStorage.removeItem('tDate');
    localStorage.removeItem('fullTextLimiter');
    localStorage.removeItem('vPubFilters');
    localStorage.removeItem('yearCardSel');
    localStorage.removeItem('dbFilters');
    localStorage.removeItem('selectedDbList');
    localStorage.removeItem('dbDisplayFilters');
    this.setPubListUpdated(false);
  }

  setFilterChecked(checked: boolean) {
    this.displayFilterSubject.next(checked);
  }

  getSelectedPubIds(): Array<string> {
    return (localStorage.getItem('selectedPubs') === null ? [] : JSON.parse(localStorage.getItem('selectedPubs')));
  }

  public displayClearFilters(): boolean | false {
    let sf = JSON.parse(localStorage.getItem('sourceFilters'));
    let df = JSON.parse(localStorage.getItem('docFilters'));
    let fromDate = JSON.parse(localStorage.getItem('fromDate'));
    let toDate = JSON.parse(localStorage.getItem('toDate'));
    let fullTextLimiter = JSON.parse(localStorage.getItem('fullTextLimiter'))

    if ((sf != null && sf.length > 0) ||
      (df != null && df.length > 0) ||
      (fromDate != null && fromDate.length > 0) ||
      (toDate != null && toDate.length > 0) ||
      (fullTextLimiter === true) ||
      (this.pubListUpdatedSubject.getValue())) {
      return true;
    }

  }

  public isDatesSelected(): boolean | false {
    let fromDate = JSON.parse(localStorage.getItem('fDate'));
    let toDate = JSON.parse(localStorage.getItem('tDate'));
    if ((fromDate != null && fromDate.length > 0) ||
      (toDate != null && toDate.length > 0))
      return true;

  }

  public getSelectedDbList(): Database[] {
    return localStorage.getItem('selectedDbList') !== null ? JSON.parse(localStorage.getItem('selectedDbList')) : [];
  }


  createDataset(dataset: DefaultDataset) {
    const apiUrl = 'api/cm/corpus';
    return this.http.post(apiUrl, dataset);
  }

  createWosDataset(dataset: DefaultDataset) {
    const apiUrl = 'api/cm/corpus/wos';
    return this.http.post(apiUrl, dataset);
  }

  createGis(dataset: DefaultDataset) {
    const apiUrl = 'api/cm/corpus/gis';
    return this.http.post(apiUrl, dataset);
  }






}
