/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-project-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./create-project-header.component";
var styles_CreateProjectHeaderComponent = [i0.styles];
var RenderType_CreateProjectHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateProjectHeaderComponent, data: {} });
export { RenderType_CreateProjectHeaderComponent as RenderType_CreateProjectHeaderComponent };
export function View_CreateProjectHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "headerContainer"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "createProjLabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create New Project"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CreateProjectHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-create-project-header", [], null, null, null, View_CreateProjectHeaderComponent_0, RenderType_CreateProjectHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.CreateProjectHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateProjectHeaderComponentNgFactory = i1.ɵccf("tdms-create-project-header", i4.CreateProjectHeaderComponent, View_CreateProjectHeaderComponent_Host_0, {}, {}, []);
export { CreateProjectHeaderComponentNgFactory as CreateProjectHeaderComponentNgFactory };
