<div class="container">
    <div class="header">
        <div class="title_div">
            <div class="title">Your Projects</div>
            <div class="table_count">(using {{sampleProjects.length}} of 5)</div>
            <div class="subtitle">Manage and interact with your custom projects using analysis methods.</div>
        </div>
        <a href="analysis/search" (click)="clearStorage()"><div class="create">Create New Project</div></a>
    </div>
    <table class="table" >
        <tr>
            <th class="header_name">NAME</th>
            <th>DATE RANGE</th>
            <th>SEARCH QUERY</th>
            <th>COUNT</th>
            <th>PUBLICATIONS</th>
            <th>ANALYSIS METHOD</th>
        </tr>

        <ng-template ngFor let-project [ngForOf]="sampleProjects">
            
            <tr [ngClass]="(selectedProjects.indexOf(project.name) > -1) ? 'selected_row' : 'row'" (click)='showProject(project)'>
                <td [ngClass]="(selectedProjects.indexOf(project.name) > -1) ? 'selected name' : 'unselected name'">{{ project.name }}</td>
                <td class="date">{{ project.dateRange }}</td>
                <td class="query">{{ project.query }}</td>
                <td class="count">{{ project.count }}</td>
                <td class="publication">{{ project.publication }}</td>
                <td class="method"> <img class="globe" src='../../../assets/images/globe.svg'/><p class="method_text">Geographic Analysis</p></td>
                <td [ngClass]="(selectedProjects.indexOf(project.name) > -1) ? 'selected_show show' : 'unselected_show show'"><p *ngIf="!(selectedProjects.indexOf(project.name) > -1)">Show actions</p><p *ngIf="(selectedProjects.indexOf(project.name) > -1)" >Hide actions</p></td>
            </tr>
            <tr class="button_row" *ngIf="(selectedProjects.indexOf(project.name) > -1)">
                <td colspan="7" class="selected_buttons">
                    <div class="selections">
                        <a href="analysis/viz/geoanalysis"><div class="GA_button">Open Geographic Visualization</div></a>
                        <div class="delete" (click)="delete(project)"><p class="delete_icon">delete</p>Delete</div>
                    </div>
                </td>
            </tr>
            <br/>
        </ng-template>
    </table>
</div>
