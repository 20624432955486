import { Database } from 'src/app/_shared/models/publication-search.model';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { PubDocumentSearchResponse, DocSearchRequest, VizDocSearchRequest, Entry, Facet, Filter } from 'src/app/_shared/models/publication-results.model';
import { tap, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VizPublicationService {
    constructor(http) {
        this.http = http;
        this.docApiRootForMcm = '/api/mcm/document/search';
        this.docCountSubject = new Subject();
        this.totalDocCount$ = this.docCountSubject.asObservable();
        this.searchRequestSubject = new Subject();
        this.searchRequest$ = this.searchRequestSubject.asObservable();
        //Build Facets stream
        this.pubFacetSubject = new Subject();
        this.pubFacet$ = this.pubFacetSubject.asObservable();
        this.sourceFacetSubject = new Subject();
        this.sourceFacet$ = this.sourceFacetSubject.asObservable();
        this.objectFacetSubject = new Subject();
        this.objectFacet$ = this.objectFacetSubject.asObservable();
        //Build Filters --> for search Request --> Filters in transforme form i.e., {"searchValue": "source Journal"}
        this.docTypeFilterSubject = new BehaviorSubject('');
        this.docTypeFilter$ = this.docTypeFilterSubject.asObservable();
        this.sourceTypeFilterSubject = new BehaviorSubject('');
        this.sourceTypeFilter$ = this.sourceTypeFilterSubject.asObservable();
        this.pubFilterSubject = new BehaviorSubject('');
        this.pubFilter$ = this.pubFilterSubject.asObservable();
        //Define subject for user currently selected filters. 
        this.currSelectedObjectTypesSubject = new BehaviorSubject([]);
        this.currSelectedObjectTypes$ = this.currSelectedObjectTypesSubject.asObservable();
        this.currSelectedSourceTypesSubject = new BehaviorSubject([]);
        this.currSelectedSourceTpes$ = this.currSelectedSourceTypesSubject.asObservable();
        this.dateFilterSubject = new Subject();
        this.dateFilter$ = this.dateFilterSubject.asObservable();
        this.fullTextLimiterSubject = new BehaviorSubject(false);
        this.fullTextLimitter$ = this.fullTextLimiterSubject.asObservable();
        //DisplayFilter Subject  (button)
        this.displayFilterSubject = new BehaviorSubject(false);
        this.displayFilter$ = this.displayFilterSubject.asObservable();
    }
    handleError(err) {
        let errorMessage;
        if (err.error instanceof ErrorEvent) {
            //network error
            errorMessage = `An error occurred: ${err.error.errorMessage}`;
        }
        else {
            //backend error
            errorMessage = `Error occurred: (${err.status}: ${err.body.error})`;
        }
        console.error(err);
        return throwError(errorMessage);
        return null;
    }
    setSearchRequest(req) {
        console.log("req => ", req);
        this.searchRequestSubject.next(req);
    }
    search(docSearchReq) {
        //store request into local store
        this.storeSearchRequest(docSearchReq);
        return this.http.post(this.docApiRootForMcm, docSearchReq);
    }
    generateSearchRequest(searchTerm = localStorage.getItem("searchTerm") ? localStorage.getItem("searchTerm") : '', sourceFilters, docFilters, pubFilters) {
        const genericReq = {
            "search": {
                "query": searchTerm
            },
            "count": 40,
            "facets": [
                { "name": "sourcetype" },
                { "name": "pubTitle" },
                { "name": "objectype" }
            ]
        };
        console.log("searchterm", searchTerm);
        genericReq.search = {
            "query": searchTerm,
            "fulltext": false,
            "publications": [],
            "filters": this.prepareFilters(sourceFilters, docFilters, pubFilters)
        };
        this.applyDateFilters(genericReq);
        this.applyFullTextLimiter(genericReq);
        return genericReq;
    }
    //ApplyFullTextLimiter
    applyFullTextLimiter(searchReq) {
        let limiter = JSON.parse(localStorage.getItem('fullTextLimiter')) === null ? false : JSON.parse(localStorage.getItem('fullTextLimiter'));
        searchReq.search.fulltext = limiter;
    }
    //applyDateFilters
    applyDateFilters(searchReq) {
        let fDate = JSON.parse(localStorage.getItem('fromDate'));
        let tDate = JSON.parse(localStorage.getItem('toDate'));
        if (fDate != null && fDate.length > 0)
            searchReq.search.startDate = new Date(fDate);
        if (tDate != null && tDate.length > 0)
            searchReq.search.endDate = new Date(tDate);
    }
    storeSearchRequest(searchReq) {
        localStorage.setItem('vsReq', JSON.stringify(searchReq));
    }
    reteriveSearchRequest() {
        return JSON.parse(localStorage.getItem('vsReq'));
    }
    currentSearchTerm() {
        let docReq = this.reteriveSearchRequest();
        return docReq != null ? docReq.search.query : '';
    }
    clearSearchRequest() {
        localStorage.removeItem('sReq');
    }
    clearRefinementData() {
        this.clearFilters();
        this.clearSearchRequest();
    }
    prepareFilters(sourceFilter, docFilter, pubTitle) {
        let filters = [];
        if (sourceFilter != null && sourceFilter.length > 0) {
            let sFilters = JSON.parse(sourceFilter);
            if (sFilters != null && sFilters.length > 0) {
                let sf = {
                    "name": "sourcetype",
                    "entries": sFilters
                };
                filters.push(sf);
            }
        }
        if (docFilter != null && docFilter.length > 0) {
            let sDocFilters = JSON.parse(docFilter);
            if (sDocFilters != null && sDocFilters.length > 0) {
                let df = {
                    "name": "objectype",
                    "entries": sDocFilters
                };
                filters.push(df);
            }
        }
        if (pubTitle != null && pubTitle.length > 0) {
            let sPubFilters = JSON.parse(pubTitle);
            if (sPubFilters != null && sPubFilters.length > 0) {
                let pf = {
                    "name": "pubTitle",
                    "entries": sPubFilters
                };
                filters.push(pf);
            }
        }
        return filters;
    }
    setDocCount(noOfHits) {
        this.docCountSubject.next(noOfHits);
    }
    setPubFacetSubject(entry) {
        this.pubFacetSubject.next(entry);
    }
    setSourceFacetSubject(entry) {
        this.sourceFacetSubject.next(entry);
    }
    setObjectFacetSubject(entry) {
        this.objectFacetSubject.next(entry);
    }
    setSourceFilterSubject(sourceFilters) {
        this.sourceTypeFilterSubject.next(sourceFilters);
    }
    setDocTypeFilterSubject(docTypeFilters) {
        this.docTypeFilterSubject.next(docTypeFilters);
    }
    setPubFilterSubject(pubFilters) {
        this.pubFilterSubject.next(pubFilters);
    }
    setDateFilterChange(b) {
        this.dateFilterSubject.next(true);
    }
    setFullTextLimiterChange(val) {
        this.fullTextLimiterSubject.next(val);
    }
    // getDatabaseSubscription(wbId: string) {
    //   //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
    //   let url = "/api/wbm/workbench/"+wbId+"/subscription/flat";
    //   return this.http.get<DbResponse>(url).pipe(
    //     tap( db => console.log( 'db==>',db)),
    //     shareReplay(1)
    //   );
    // }
    // getDatabaseSubscription2(wbId: string) {
    //   //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
    //   let url = "/api/wbm/workbench/"+wbId+"/subscription";
    //   return this.http.get<DbResponse>(url)
    // }
    getMonikerInfo(moniker) {
        let url = "/api/wbm/product/" + moniker;
        return this.http.get(url).pipe(tap(db => console.log('getting moniker =>', db)), shareReplay(1));
    }
    getPubFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'pubTitle');
    }
    getTotalPublications(facets) {
        return this.extractSubjectsFromFacets(facets, 'pubTitle').length;
    }
    getSourceFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'sourcetype');
    }
    getDocTypeFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'objectype');
    }
    extractSubjectsFromFacets(facets, facetType) {
        if (facets != null && facets.length > 0)
            return facets.find(facet => facet.name === facetType).entries;
        return [];
    }
    clearFilters() {
        localStorage.removeItem('sourceFilters');
        localStorage.removeItem('docFilters');
        localStorage.removeItem('fromDate');
        localStorage.removeItem('toDate');
        localStorage.removeItem('fullTextLimiter');
    }
    setFilterChecked(checked) {
        this.displayFilterSubject.next(checked);
    }
    getSelectedPubIds() {
        return (localStorage.getItem('selectedPubs') === null ? [] : JSON.parse(localStorage.getItem('selectedPubs')));
    }
    displayClearFilters() {
        let sf = JSON.parse(localStorage.getItem('sourceFilters'));
        let df = JSON.parse(localStorage.getItem('docFilters'));
        let fromDate = JSON.parse(localStorage.getItem('fromDate'));
        let toDate = JSON.parse(localStorage.getItem('toDate'));
        let fullTextLimiter = JSON.parse(localStorage.getItem('fullTextLimiter'));
        if ((sf != null && sf.length > 0) ||
            (df != null && df.length > 0) ||
            (fromDate != null && fromDate.length > 0) ||
            (toDate != null && toDate.length > 0) ||
            (fullTextLimiter === true)) {
            return true;
        }
    }
    isDatesSelected() {
        let fromDate = JSON.parse(localStorage.getItem('fromDate'));
        let toDate = JSON.parse(localStorage.getItem('toDate'));
        if ((fromDate != null && fromDate.length > 0) ||
            (toDate != null && toDate.length > 0))
            return true;
    }
    createProject(dataset) {
        const apiUrl = 'api/mcm/corpus';
        return this.http.post(apiUrl, dataset);
    }
    getProjects() {
        const apiUrl = 'api/mcm/corpus';
        return this.http.get(apiUrl);
    }
    getProjectsDetails(corpusId) {
        const apiUrl = 'api/mcm/corpus/' + corpusId;
        return this.http.get(apiUrl);
    }
    deleteProject(corpusId) {
        const apiUrl = 'api/mcm/corpus/' + corpusId;
        return this.http.delete(apiUrl);
    }
}
VizPublicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VizPublicationService_Factory() { return new VizPublicationService(i0.ɵɵinject(i1.HttpClient)); }, token: VizPublicationService, providedIn: "root" });
