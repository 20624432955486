import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { AnalysisRoutingModule } from './analysis-routing.module';
import { AnalysisDashboardComponent } from './analysis-dashboard/analysis-dashboard.component';
import { AnalysisDashboardHeaderComponent } from './analysis-dashboard/analysis-dashboard-header/analysis-dashboard-header.component';
import { AnalysisSampleProjectsComponent } from './analysis-dashboard/analysis-sample-projects/analysis-sample-projects.component';
import { AnalysisDatasetComponent } from './analysis-dataset/analysis-dataset.component';
import { AnalysisFindContentComponent } from './analysis-dataset/analysis-find-content/analysis-find-content.component';
import { AnalysisRefineContentComponent } from './analysis-dataset/analysis-refine-content/analysis-refine-content.component';
import { AnalysisCreateContentComponent, ProjectCreateSuccessModal } from './analysis-dataset/analysis-create-content/analysis-create-content.component';
import { CreateProjectHeaderComponent } from './analysis-dataset/create-project-header/create-project-header.component';
import { CreateProjectProgressBarComponent } from './analysis-dataset/create-project-progress-bar/create-project-progress-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AnalysisDeleteModal, AnalysisProjectsComponent } from './analysis-dashboard/analysis-projects/analysis-projects.component';
import { EmailVerificationComponent } from '../create-account/email-verification/email-verification.component';
import { EmailExpireComponent } from '../create-account/email-verification/email-expire.component';
import { DatasetModule } from '../dataset';
import { DocumentationComponent } from './documentation/documentation.component';
import { VizDashboardDocTabComponent } from './documentation/documentation tabs/viz-dashboard-doc-tab/viz-dashboard-doc-tab.component';
import { CreateProjectDocTabComponent } from './documentation/documentation tabs/create-project-doc-tab/create-project-doc-tab.component';
import { GeoAnalysisDocTabComponent } from './documentation/documentation tabs/geo-analysis-doc-tab/geo-analysis-doc-tab.component';
import { TopicModelingDocTabComponent } from './documentation/documentation tabs/topic-modeling-doc-tab/topic-modeling-doc-tab.component';
import { SelectVizComponent } from './analysis-dataset/select-viz/select-viz.component';
import { SelectVizFooterComponent } from './analysis-dataset/select-viz/select-viz-footer/select-viz-footer.component';
import { SentimentAnalysisDocTabComponent } from './documentation/documentation tabs/sa-doc-tab/sa-doc-tab.component';
import { SubwayMapComponent } from '../create-account/subway-map/subway-map.component';;



@NgModule({
  declarations: [
    AnalysisDashboardComponent,
    AnalysisDashboardHeaderComponent,
    AnalysisSampleProjectsComponent,
    AnalysisDatasetComponent,
    AnalysisFindContentComponent,
    AnalysisRefineContentComponent,
    AnalysisCreateContentComponent,
    CreateProjectHeaderComponent,
    AnalysisProjectsComponent,
    CreateProjectProgressBarComponent,
    // EmailVerificationComponent,
    // EmailExpireComponent,
    ProjectCreateSuccessModal,
    AnalysisDeleteModal,
    DocumentationComponent,
    VizDashboardDocTabComponent,
    CreateProjectDocTabComponent,
    GeoAnalysisDocTabComponent,
    TopicModelingDocTabComponent,
    SentimentAnalysisDocTabComponent,
    SelectVizComponent,
    SelectVizFooterComponent,
    // SubwayMapComponent
  ],
  imports: [
    CommonModule,
    AnalysisRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DatasetModule
  ],
  entryComponents: [
    ProjectCreateSuccessModal,
    AnalysisDeleteModal,
    DocumentationComponent,
  ]
})
export class AnalysisModule { }
