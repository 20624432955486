<div class="select-viz">
    <tdms-create-project-header></tdms-create-project-header>
    <tdms-create-project-progress-bar stage='select'></tdms-create-project-progress-bar>

    <div fxLayout="column" fxLayoutAlign="center center" class="container">
        <div class="select-header" fxLayoutAlign="center">Which data visualization would you like to create?</div>
        <div class="select-header-guided-text" fxLayoutAlign="center">You must select one or more visualization methods to continue.</div>

        <div class="cards" >

            <div class="viz-card">
                <div class="card-header card-geo" >
                    <div fxLayoutAlign="end">
                        <mat-checkbox [(ngModel)]= "geoCardCheck" 
                                    color="primary" 
                                    aria-label="select geo vizualtion method" 
                                    #geoCheckBox (change)='toggleGeoCardSelection(geoCheckBox.checked)'> 
                        </mat-checkbox>
                    </div>
                    
                </div>
                <div class="card-label" fxLayoutAlign="center center">
                    Geographic Analysis
                </div>
            </div>

            <div class="viz-card">
                <div class="card-header card-tm">
                    <div fxLayoutAlign="end">
                        <mat-checkbox 
                                [(ngModel)]= "tmCardCheck" 
                                color="primary" 
                                #tmCheckBox (change)='toggleTmCardSelection(tmCheckBox.checked)'> 
                        </mat-checkbox>
                    </div>
                    
                </div>
                <div class="card-label" fxLayoutAlign="center center">
                    Topic Modeling
                </div>
            </div>

            <div class="viz-card">
                <div class="card-header card-sa">
                    <div fxLayoutAlign="end">
                        <mat-checkbox 
                                [(ngModel)]= "saCardCheck" 
                                color="primary" 
                                #saCheckBox (change)='toggleSaCardSelection(saCheckBox.checked)'> 
                        </mat-checkbox>
                    </div>
                    
                </div>
                <div class="card-label" fxLayoutAlign="center center">
                    Sentiment Analysis
                </div>
            </div>
            
        
        </div>
    </div>
    <tdms-select-viz-footer></tdms-select-viz-footer>
</div>

