<div class="container" *ngIf = 'isLoading'>
    <div  class="center">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>
</div>


<div class="container" *ngIf="isLoadingComplete()">
    <div class="header">
        <div class="title_div">
            <div class="title">Your Projects</div>
            <div class="table_count">(using {{getProjectCount()}} of {{maxProjects}})</div>
            <div class="subtitle">Manage and interact with your custom projects using visualization methods.</div>
        </div>
        <a href="analysis/select" (click)="clearStorage()" *ngIf="yourProjects && getProjectCount() < maxProjects"><div class="create">Create New Project</div></a>
        <div class="disabled_button create" *ngIf="!(yourProjects && getProjectCount() < maxProjects)">Create New Project</div>
    </div>
    <div *ngIf="!yourProjects || (yourProjects.length < 1)" class='empty'>Click "Create New Project" to get started</div>
    <table class="table" >
        <tr *ngIf="yourProjects && (yourProjects.length > 0)">
            <th class="header_name">NAME</th>
            <th>DATE RANGE</th>
            <th>SEARCH QUERY</th>
            <th class="header_doccount">DOCUMENT COUNT</th>
            <th class="header_publications">PUBLICATIONS</th>
            <th>VISUALIZATION METHOD</th>
        </tr>

        <ng-template ngFor let-project [ngForOf]="yourProjects">

            <tr *ngIf="!getInteractablityStatus(project)" class='disabled row' (mouseover)="hover=project.id" (mouseout)="hover=''">
                <td class='unselected name'>{{ project.name }}</td>
                <td class="date">{{ getDate(project) }}</td>
                <td class="query">{{ project.search.query }}</td>
                <td class="count">{{ project.docCount }}</td>
                <td class="publication">{{getPublications(project)}}</td>
                <td class="method" > 
                    <div class="method-info" *ngIf="isGeoAnalysis(project)">
                        <img class="globe" src='../../../assets/images/globe.svg' />
                        <p class="method_text" >Geographic Analysis</p>
                    </div>
                    <div class="method-info" *ngIf="isTopicAnalysis(project)">
                        <img class="topic" src='../../../assets/images/topic-icon.svg' />
                        <p class="method_text">Topic Modeling</p>
                    </div>
                    <div class="method-info" *ngIf="isSentimentAnalysis(project)">
                        <img class="topic" src='../../../assets/images/sa-icon.svg' />
                        <p class="method_text">Sentiment Analysis</p>
                    </div>
                </td>
                <td class="selected_show show"></td>
            </tr>
            
            <tr>
                <td colspan="7" *ngIf="!getInteractablityStatus(project) && project.status !== 'deleting' && hover === project.id" >
                    <p class="speech-bubble" *ngIf="project.status === 'queued'">
                        We are applying your selected visualization method(s) to your selected dataset. This can take up to several hours.
                    </p>
                </td>
            </tr>
                
            
            <tr *ngIf="getInteractablityStatus(project)" 
                [ngClass]="(selectedProjects.indexOf(project.id) > -1) ? 'selected_row' : 'row'" 
                (click)='showProject(project)'
                (keyup.enter)='showProject(project)'>
                <td [ngClass]="(selectedProjects.indexOf(project.id) > -1) ? 'selected name' : 'unselected name'">{{ project.name }}</td>
                <td class="date">{{ getDate(project) }}</td>
                <td class="query">{{ project.search.query }}</td>
                <td class="count">{{ project.docCount }}</td>
                <td class="publication">{{getPublications(project)}}</td>
                <td class="method" > 
                    <div class="method-info" *ngIf="isGeoAnalysis(project)">
                        <img class="globe" src='../../../assets/images/globe.svg' />
                        <p class="method_text" >Geographic Analysis</p>
                    </div>
                    <div class="method-info" *ngIf="isTopicAnalysis(project)">
                        <img class="topic" src='../../../assets/images/topic-icon.svg' />
                        <p class="method_text">Topic Modeling</p>
                    </div>
                    <div class="method-info" *ngIf="isSentimentAnalysis(project)">
                    <!-- <div class="method-info" *ngIf="isSentimentAnalysis(project)"> -->
                        <img class="topic" src='../../../assets/images/sa-icon.svg' />
                        <p class="method_text">Sentiment Analysis</p>
                    </div>

                </td>
                <td [ngClass]="(selectedProjects.indexOf(project.id) > -1) ? 'selected_show show' : 'unselected_show show'">
                    <p *ngIf="!(selectedProjects.indexOf(project.id) > -1)" role="button" tabindex="0">Show actions</p>
                    <p *ngIf="(selectedProjects.indexOf(project.id) > -1)" tabindex="0">Hide actions</p>
                </td>
            </tr>
            <tr class="button_row" *ngIf="(selectedProjects.indexOf(project.id) > -1)">
                <td colspan="7" class="selected_buttons">
                    <div fxLayout="row" fxLayoutAlign="center">
                        <div *ngIf="getGeoStatus(project) === 'completed'" 
                            (click)="goToGeoAnalysis(project)" 
                            (keyup.enter)="goToGeoAnalysis(project)"
                            class="GA_button" tabindex="0">Open Geographic Visualization
                        </div>
                        <div *ngIf="getTopicStatus(project) === 'completed'" 
                            (click)="goToTopicAnalysis(project)" 
                            (keyup.enter)="goToTopicAnalysis(project)"
                            class="GA_button" tabindex="0">Open Topic Modeling Visualization
                        </div>
                        <div *ngIf="getSentimentStatus(project) === 'completed'" 
                            (click)="goToSentimentAnalysis(project)" 
                            (keyup.enter)="goToSentimentAnalysis(project)"
                            class="GA_button" tabindex="0">Open Sentiment Visualization
                        </div>

                        
                        <button *ngIf="isTopicAnalysis(project) && getTopicStatus(project) !== 'completed' && 
                                    getTopicStatus(project) !== ''" 
                                    class="disabled_button GA_button" 
                                    (mouseover)="failedHover=project.id" 
                                    (mouseout)="failedHover=''" 
                                    tabindex="0"
                                    aria-disabled="true"
                                    aria-label="Open Topic Modeling Visualization disabled">Open Topic Modeling Visualization
                        </button>
                        <button *ngIf="isGeoAnalysis(project) && getGeoStatus(project) !== 'completed'" 
                            class="disabled_button GA_button" 
                            (mouseover)="failedHover=project.id" 
                            (mouseout)="failedHover=''" 
                            tabindex="0"
                            aria-disabled="true"
                            aria-label="Open Geographic Visualization disabled">Open Geographic Visualization
                        </button>
                        <button *ngIf="isSentimentAnalysis(project) && getSentimentStatus(project) !== 'completed'" 
                            class="disabled_button GA_button" 
                            (mouseover)="failedHover=project.id" 
                            (mouseout)="failedHover=''" 
                            tabindex="0"
                            aria-disabled="true"
                            aria-label="Open Sentiment Visualization disabled">Open Sentiment Visualization
                        </button>

                        <div *ngIf="(getTopicStatus(project) === 'failed' || getGeoStatus(project) === 'failed') && 
                            failedHover === project.id" 
                            class="failed_hover">
                            Your project has encountered an error and was unable to complete. Please delete your project and start again.
                        </div>

                        
                        <div class="delete" 
                            (click)="delete(project)" 
                            (keyup.enter)="delete(project)"
                            tabindex="0" 
                            role="button" 
                            aria-label="delete">
                            <p class="delete_icon">delete</p>Delete
                        </div>
                    </div>
                </td>
            </tr>
            <br/>
        </ng-template>
    </table>
</div>
