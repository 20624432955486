<tdms-create-project-header></tdms-create-project-header>
<tdms-create-project-progress-bar stage='create'></tdms-create-project-progress-bar>

<div *ngIf = 'isLoading' class="center">
    <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
</div>
<div *ngIf = '!isLoading'>
    <div class="create_header">
        <div class="summary">Project Summary</div>
        <div class="details">
            <div class="bold summary_label">Document Count: </div> <div  class="summary_label">{{docCount | number}}</div> <div></div>
        </div>
        <div class="details">
            <div  class="bold summary_label">Publications: </div> <div class="summary_label">{{getContentList() }}</div> <div></div>
        </div>
        <div class="details">
            <div  class="bold summary_label">Selected Visualization: </div> <div class="summary_label">{{ selectedVizText }}</div> <div></div>
        </div>
       
        
    </div>


    <div class="create_body">
        <div class="dataset_details">Project Details</div>

        <form [formGroup]="createProject" (ngSubmit)="onCreate(createProject.value)">
            <div><p class="name label">Name</p><p class="required label">*</p></div>
            <textarea [ngClass]= "isError ? 'error textbox' : 'textbox'" form="createProject" formControlName='name' placeholder="Enter Descriptive Project Name" maxlength="60"></textarea>
            <p *ngIf="isError" class= "error_message">{{errorMessage}}</p>
            <div *ngIf="!isCreating" (click)="onCreate(createProject.value)" class="create">Create Project</div>
            <div *ngIf="isCreating" class="create disabled"><div class="spinner"><mat-spinner [diameter]="30" [strokeWidth]="3"></mat-spinner></div></div>
        </form>
    </div>
</div>

