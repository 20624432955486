<div ngClass='container' 
        ngClass.lt-lg='container-ltLg' 
        *ngIf="!isAccountCreated" 
        fxLayout="row" 
        fxLayoutAlign="start stretch"  
        fxLayout.lt-lg="column">
    <div class='container-create' fxFlex="55" fxLayout="column" fxLayoutAlign="start start">
        <!-- <p>Form Status: {{ createAccountForm.status}}</p>
        <p>isLoading: {{ isLoading }}</p>

        <p>Form value: {{ createAccountForm.value | json}}</p>
        <p>.identityCheckDone {{ identityCheckDone }}</p>
        <p>.hasSubscriptionCheckDone {{ hasSubscriptionCheckDone }}</p>
        
        <p>.hasSubscription {{ hasSubscription }}</p>
        (keydown.Tab)="onKeyup(emailInput.value)"
        <p>.isAccountExistsPlatformOnly {{ isAccountExistsPlatformOnly }}</p> -->
        <div class="error-info" *ngIf="errMsg"> {{errMsg.status +':  ' +errMsg.statusText }}</div>

        <div class="header">
            <h1 class='title'>Create Account</h1>
            <p class='require'>Required*</p>
        </div>
        
        <form class="forms" [formGroup]="createAccountForm">
            <h2 class='label' id="email"><p class='require'>*</p>Enter your university or institutional email address</h2>
            <div class="input-fields">
                <input 
                    type="email" 
                    [class] = "emailError ? 'error input' : 'input'" 
                    placeholder="University or institution email" 
                    onfocus="this.placeholder = ''" 
                    onblur="this.placeholder = 'University or institution email'" 
                    form="createAccountForm"
                    formControlName='email'
                    #emailInput
                    (keyup)="onKeyup(emailInput.value, $event)"
                    aria-labelledby="email"
                    required="true"
                    autofocus
                />
                <div class="icon"></div>
            </div>
            <mat-spinner [diameter]="20" [strokeWidth]="3" *ngIf="isEmailCheckLoading"></mat-spinner>
        <div *ngIf="!isEmailCheckLoading">
            <div *ngIf="institutionList.length > 0">
                <h2 class='label' id="institution"><p class='require'>*</p>Select your institution</h2>
                <div class="input-fields" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
                    <select class="select" 
                            form="createAccountForm" 
                            formControlName='institution' 
                            aria-labelledby="institution" 
                            required="true" 
                            (change)="checkAccess($event.target.value)">
                        <option 
                            *ngFor="let acct of institutionList; let i = index" 
                            [selected]='i === 0'
                            [value]="acct.accountId">{{acct.accountName}}
                        </option>
                    </select>
                    <div class="tooltip" tabindex="0" aria-label="The account you select will inform which content you have access to within TDM Studio">
                        <i class="icon bi-question-circle" ></i>
                        <span ngClass="tooltiptext" ngClass.lt-md="tooltiptext-ltMd" id="tooTip">The account you select will inform which content you have access to within TDM Studio</span>
                    </div>    
                </div>
            </div>
            <mat-spinner [diameter]="20" [strokeWidth]="3" *ngIf="isAccessCheckLoading"></mat-spinner>

            <div *ngIf="displayRequestTrail">
                <div  class="no-sub-box"  fxLayout="column" fxLayoutAlign="space-between start">
                    <p class="no-sub-text bold" aria-live="assertive">Your institution doesn't have access to TDM Studio.</p>
                    <p class="no-sub-text">Would you like to request a trial?</p>
                    <div class="no-sub-controls" fxLayout="row" fxLayoutAlign="space-between center">
                        <a href="https://proquest.iad1.qualtrics.com/jfe/form/SV_6VT4HAsZfkPHHka" target="_blank">
                            <div class="no-sub-button">Request Trial</div><span class="screen-reader-only">(opens in a new tab)</span>
                        </a>
                    </div>
                </div>
            </div>
            
            <!-- password field-->
            <div *ngIf="!isAccountExistsPlatformOnly && !displayRequestTrail">                
                <h2 class='label' id="password"><p class='require'>*</p>Create Password</h2>
                <div class="input-fields" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
                    <input [type]="isShowPassword ? 'text' : 'password'" 
                        [ngClass] = "passWordError ? 'error input' : 'input' " 
                        placeholder="Password" 
                        onfocus="this.placeholder = ''" 
                        onblur="this.placeholder = 'Password'" 
                        form="createAccountForm"
                        formControlName='password'
                        (keyup)="checkPassword($event.target.value)"
                        aria-labelledby="password"
                        aria-describedby="passwordHints" />

                    <i class="icon bi-eye" *ngIf="isShowPassword" (click)="isShowPassword=!isShowPassword" (keyup.enter)="isShowPassword=!isShowPassword" tabindex="0" aria-label="showing password"></i>
                    <i class="icon bi-eye-slash" *ngIf="!isShowPassword" (click)="isShowPassword=!isShowPassword" (keyup.enter)="isShowPassword=!isShowPassword" tabindex="0" aria-label="hiding password"></i>
                </div>
                <div class='password_criteria' fxLayout="row" fxLayoutAlign="space-between start" id="passwordHints">
                    <div fxLayout="column" fxLayoutAlign="space-between start">
                        <span class='list'><span [ngClass]="criteria[0] ? 'check' : 'uncheck'">A</span>8 characters minimum</span>
                        <span class='list'><span [ngClass]="criteria[1] ? 'check' : 'uncheck'">A</span>One capital letter</span>
                        <span class='list'><span [ngClass]="criteria[2] ? 'check' : 'uncheck'">A</span>One lowercase letter</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-between start">
                        <span class='list'><span [ngClass]="criteria[3] ? 'check' : 'uncheck'">A</span>One number </span>
                        <span class='list'><span [ngClass]="criteria[4] ? 'check' : 'uncheck'">A</span>One special character</span>
                    </div>
                </div>
            </div>

            <div class="consent-submit-container-wrapper" *ngIf="!displayRequestTrail">
                <!-- consent_box-->
                <div class='consent_box'>
                    <input type="checkbox" 
                            class="checkbox"  
                            aria-labelledby="consent"
                            formControlName="acceptTerms"
                            id="acceptTerms"
                            required="true">
                    <p class='consent' id="consent">I consent to the collection and use of my personal information consistent with the 
                        <a class="consent_link" href="https://about.proquest.com/en/about/privacy-home/" target="_blank">Privacy Policy</a>
                        , and I acknowledge that the use of the service is subject to the 
                        <a class="consent_link" href=" https://about.proquest.com/en/about/Supplemental-Terms-of-Use-TDM-Studio" target="_blank">Terms and Conditions</a>
                        . Without your consent, we cannot create an account.<span class='require'>*</span></p>
                </div>

                <!--Submit button-->
                <div class='sumbit_container'>
                    <div *ngIf="!isAccountExistsPlatformOnly">
                        <button mat-raised-button [class]="(!createAccountForm.valid || !hasSubscription) ? 'disabled create' : 'create'" 
                                                color="primary" 
                                                (click)="onSubmit(createAccountForm.value)" 
                                                [disabled]="!createAccountForm.valid || !hasSubscription"
                                                [attr.aria-invalid]="createAccountForm.valid && hasSubscription">
                                                Create Account
                        </button>
                        <a href='/home'><p class='cancel'>Cancel</p></a>
                    </div>
                    <div *ngIf="isAccountExistsPlatformOnly" class="continue-with-my-res">
                        <p class="pro-quest-account-found-header">ProQuest account found</p>
                        <p class="pro-quest-account-found-content">You have a ProQuest account registered with this email address. You can use your ProQuest account for TDM Studio.</p>
                        <button mat-raised-button [class]="(!createAccountForm.valid || !hasSubscription) ? 'disabled create' : 'create'" 
                                                color="primary" 
                                                (click)="onSubmitMr(createAccountForm.value)" 
                                                [disabled]="!createAccountForm.valid || !hasSubscription"
                                                [attr.aria-invalid]="createAccountForm.valid && hasSubscription">
                                                Continue with ProQuest Account
                        </button>
                        <a href='/home'><p class='cancel'>Cancel</p></a>
                    </div>
                </div>

            </div>
        </div>    
        </form>


    </div>

    <div class="subway-map" fxFlex="45">
        <tdms-subway-map [step]="'create'"></tdms-subway-map>
    </div>
</div>

<div ngClass='container' ngClass.lt-lg='container-ltLg' *ngIf="isAccountCreated" fxLayout="row" fxLayoutAlign="start stretch"  fxLayout.lt-lg="column">
    <div ngClass='container-create'  ngClass.lt-lg='container-create-ltLg' fxFlex="55" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="start start" aria-live="polite">
        <div class="title-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <div class="icon-bubble"><i class="bi-envelope"></i></div>
            <h1 class='verify-title' >Verify Your Email</h1>
        </div>
        <span class="subtitle">Check your email to verify and log in to TDM Studio.</span>

    </div>

    <div class="subway-map" fxFlex="45">
        <tdms-subway-map [step]="'created'"></tdms-subway-map>
    </div>
</div>