/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./analysis-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./analysis-sample-projects/analysis-sample-projects.component.ngfactory";
import * as i8 from "./analysis-sample-projects/analysis-sample-projects.component";
import * as i9 from "./analysis-projects/analysis-projects.component.ngfactory";
import * as i10 from "./analysis-projects/analysis-projects.component";
import * as i11 from "../analysis-dataset/analysis-refine-content/analysis-refinement.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../viz/geoviz.service";
import * as i15 from "./analysis-dashboard.component";
import * as i16 from "../../auth/auth.service";
import * as i17 from "../../workbenchDashboard/workbench.service";
var styles_AnalysisDashboardComponent = [i0.styles];
var RenderType_AnalysisDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnalysisDashboardComponent, data: {} });
export { RenderType_AnalysisDashboardComponent as RenderType_AnalysisDashboardComponent };
function View_AnalysisDashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "spinner-container mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = 20; var currVal_4 = 3; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AnalysisDashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errMsg.error; _ck(_v, 1, 0, currVal_0); }); }
function View_AnalysisDashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "right_underline"]], null, null, null, null, null))], null, null); }
function View_AnalysisDashboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-analysis-sample-projects", [], null, null, null, i7.View_AnalysisSampleProjectsComponent_0, i7.RenderType_AnalysisSampleProjectsComponent)), i1.ɵdid(1, 114688, null, 0, i8.AnalysisSampleProjectsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AnalysisDashboardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-analysis-projects", [], null, null, null, i9.View_AnalysisProjectsComponent_0, i9.RenderType_AnalysisProjectsComponent)), i1.ɵdid(1, 114688, null, 0, i10.AnalysisProjectsComponent, [i11.VizPublicationService, i12.Router, i13.MatDialog, i14.GeovizService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AnalysisDashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "dashboard-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "tour_bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get Started with TDM Studio"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use interactive data visualizations to uncover patterns and connections in content from newspapers, dissertations, and theses."])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "project_toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleProjects(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Your Projects "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_4)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_5)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_6)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isSampleProject; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.isSampleProject; _ck(_v, 14, 0, currVal_2); var currVal_3 = !_co.isSampleProject; _ck(_v, 16, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isSampleProject ? "toggle_on" : "toggle_off"); _ck(_v, 9, 0, currVal_0); }); }
export function View_AnalysisDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisDashboardComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errMsg; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.isLoading && (_co.errMsg == null)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AnalysisDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-analysis-dashboard", [], null, null, null, View_AnalysisDashboardComponent_0, RenderType_AnalysisDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i15.AnalysisDashboardComponent, [i12.Router, i16.AuthService, i17.WorkbenchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnalysisDashboardComponentNgFactory = i1.ɵccf("tdms-analysis-dashboard", i15.AnalysisDashboardComponent, View_AnalysisDashboardComponent_Host_0, {}, {}, []);
export { AnalysisDashboardComponentNgFactory as AnalysisDashboardComponentNgFactory };
