import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tdms-create-project-header',
  templateUrl: './create-project-header.component.html',
  styleUrls: ['./create-project-header.component.scss']
})
export class CreateProjectHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
