<div class="main-container">
    <tdms-create-project-header></tdms-create-project-header>
    <tdms-create-project-progress-bar stage='find'></tdms-create-project-progress-bar>

    <div class="container" fxLayout="column"  fxLayoutAlign="center">
            <div class="searchLabel">What are you researching?</div>
            <div class="filter_bar">
                <div class="search_div">
                <input   #filterText 
                            type="text" 
                            class="search" 
                            placeholder="Enter search here..." 
                            onfocus="this.placeholder = ''"
                            onblur="this.placeholder = 'Enter search here...'" 
                            (keyup.enter)="submitSearch(filterText.value)"
                            />
        
                <span *ngIf="filterText.value !== ''" class="clear" (click)="filterText.value = ''">n</span>
                </div>
                <div class="filter" (click)="submitSearch(filterText.value)">;</div>
            </div>    
    </div>
 </div>   