import { AnalysisAccountResponse } from './models/account.model';
import { Subject } from 'rxjs';
import { User, UserInfo } from '../workbenchDashboard/workbench.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AnalysisService {
    constructor(http) {
        this.http = http;
        this.cardSelectionSubject = new Subject();
        this.selectViz$ = this.cardSelectionSubject.asObservable();
        this.userApiRootForMm = "api/mum/";
    }
    getUserAccounts(email) {
        const apiUrl = this.userApiRootForMm + 'accounts?email=' + encodeURIComponent(email);
        return this.http.get(apiUrl);
    }
    getAccount(acctId) {
        const apiUrl = this.userApiRootForMm + 'accounts/' + acctId + '/access';
        return this.http.get(apiUrl);
    }
    getUserStatus(email) {
        const apiUrl = this.userApiRootForMm + 'user/' + encodeURIComponent(email) + '/status';
        return this.http.get(apiUrl);
    }
    ///api/um/user
    getAnalysisUserInfo(email) {
        //const apiUrl = this.userApiRootForMm + 'user/' + email + '/status'
        const apiUrl = this.userApiRootForMm + 'user';
        return this.http.get(apiUrl);
    }
    getDashboardLink() {
        let tdmUser = JSON.parse(localStorage.getItem('user'));
        let redirect = '/home';
        if (tdmUser && tdmUser.isAnalysisUser && tdmUser.isStudioUser) {
            redirect = '/workbenchdashboard';
        }
        else if (tdmUser && tdmUser.isStudioUser) {
            redirect = '/workbenchdashboard';
        }
        else if (tdmUser && tdmUser.isAnalysisUser) {
            redirect = '/analysis/dashboard';
        }
        return redirect;
    }
    setVizCardSelection(selection) {
        this.cardSelectionSubject.next(selection);
    }
}
AnalysisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalysisService_Factory() { return new AnalysisService(i0.ɵɵinject(i1.HttpClient)); }, token: AnalysisService, providedIn: "root" });
