import { BehaviorSubject, Subject, throwError, from } from 'rxjs';
import { tap, shareReplay, toArray, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PublicationService {
    constructor(http) {
        this.http = http;
        this.docApiRootForCm = '/api/cm/document/search';
        this.wosApiDocSearch = '/api/cm/document/search/wos';
        this.pubApiRootForCm = '/api/cm/publication/search';
        this.docCountSubject = new Subject();
        this.totalDocCount$ = this.docCountSubject.asObservable();
        this.searchRequestSubject = new Subject();
        this.searchRequest$ = this.searchRequestSubject.asObservable();
        //Build Facets stream
        this.pubFacetSubject = new Subject();
        this.pubFacet$ = this.pubFacetSubject.asObservable();
        this.sourceFacetSubject = new Subject();
        this.sourceFacet$ = this.sourceFacetSubject.asObservable();
        this.objectFacetSubject = new Subject();
        this.objectFacet$ = this.objectFacetSubject.asObservable();
        //Build Filters --> for search Request --> Filters in transforme form i.e., {"searchValue": "source Journal"}
        this.pubFilterSubject = new BehaviorSubject('');
        this.pubFilter$ = this.pubFilterSubject.asObservable();
        this.docTypeFilterSubject = new BehaviorSubject('');
        this.docTypeFilter$ = this.docTypeFilterSubject.asObservable();
        this.sourceTypeFilterSubject = new BehaviorSubject('');
        this.sourceTypeFilter$ = this.sourceTypeFilterSubject.asObservable();
        this.dbFilterSubject = new BehaviorSubject('');
        this.dbFilter$ = this.dbFilterSubject.asObservable();
        this.dbProductSubject = new BehaviorSubject('');
        this.dbProduct$ = this.dbProductSubject.asObservable();
        //Define subject for user currently selected filters.
        this.currSelectedObjectTypesSubject = new BehaviorSubject([]);
        this.currSelectedObjectTypes$ = this.currSelectedObjectTypesSubject.asObservable();
        this.currSelectedSourceTypesSubject = new BehaviorSubject([]);
        this.currSelectedSourceTpes$ = this.currSelectedSourceTypesSubject.asObservable();
        this.dateFilterSubject = new Subject();
        this.dateFilter$ = this.dateFilterSubject.asObservable();
        this.fullTextLimiterSubject = new BehaviorSubject(false);
        this.fullTextLimitter$ = this.fullTextLimiterSubject.asObservable();
        //DisplayFilter Subject  (button)
        this.displayFilterSubject = new BehaviorSubject(false);
        this.displayFilter$ = this.displayFilterSubject.asObservable();
        this.pubListUpdatedSubject = new BehaviorSubject(false);
        this.pubListUpdated$ = this.pubListUpdatedSubject.asObservable();
        this.dateRangeSubject = new BehaviorSubject('');
        this.dateRangeSubject$ = this.dateRangeSubject.asObservable();
        this.lazyPubFacetSubject = new Subject();
        this.lazyPubFacetSubject$ = this.lazyPubFacetSubject.asObservable();
        this.lazyPubFacetSubjectV1 = new Subject();
        this.lazyPubFacetSubjectV1$ = this.lazyPubFacetSubjectV1.asObservable();
        this.pubFilterValues = () => {
            let val = JSON.parse(localStorage.getItem('pubFilters'));
            if (val != null && val.length > 0) {
                return val;
            }
            else {
                return [];
            }
        };
        this.sourceFilterValues = () => {
            let val = JSON.parse(localStorage.getItem('sourceFilters'));
            if (val != null && val.length > 0)
                return val;
            else
                return [];
        };
        this.dbFilterValues = () => {
            let val = JSON.parse(localStorage.getItem('dbFilters'));
            if (val != null && val.length > 0)
                return val;
            else
                return [];
        };
        this.docFilterValues = () => {
            let val = JSON.parse(localStorage.getItem('docFilters'));
            if (val != null && val.length > 0) {
                return val;
            }
            else {
                return [];
            }
        };
    }
    handleError(err) {
        let errorMessage;
        if (err.error instanceof ErrorEvent) {
            //network error
            errorMessage = `An error occurred: ${err.error.errorMessage}`;
        }
        else {
            //backend error
            errorMessage = `Error occurred: (${err.status}: ${err.body.error})`;
        }
        console.error(err);
        return throwError(errorMessage);
        return null;
    }
    setSearchRequest(req) {
        console.log("req => ", req);
        this.searchRequestSubject.next(req);
    }
    search(docSearchReq) {
        //store request into local store
        this.storeSearchRequest(docSearchReq);
        return this.http.post(this.docApiRootForCm, docSearchReq);
    }
    /**
     * This method is used to get the PubFacets
     * @param docSearchReq
     * @returns
     */
    searchForPubFacets(docSearchReq) {
        //store request into local store
        this.storeSearchRequest(docSearchReq);
        return this.http.post(this.docApiRootForCm, docSearchReq);
    }
    wosSearch(docSearchReq) {
        //store request into local store
        this.storeWosSearchRequest(docSearchReq);
        return this.http.post(this.wosApiDocSearch, docSearchReq);
    }
    //wosDocSearch
    /**
     * This method generates the searchRequest payload used for multiple API endpoints.
     * 1. DocSearchRequest for Ascending
     * 2. DocSearchRequest for Descending
     * 3. DocSearchRequest only get the PubFacet information (Lazy Loading...)
     * @reqType parameter allFAceets or pubFacet.
     *
     * @param searchTerm
     * @param wbId
     * @param publications
     * @param dbs
     * @param sortOrder
     * @param sourceFilters
     * @param docFilters
     * @param dbFilters
     * @param reqType
     * @returns
     */
    generateSearchRequest(searchTerm = '', wbId, sortOrder, reqType = 'allFacets') {
        let isDatabase = localStorage.getItem('datasetType') === 'Database' ? true : false;
        const allFacetsProp = [{ "name": "sourcetype" }, { "name": "objectype" }];
        const pubFacetsProp = [{ "name": "pubTitle" }];
        const genericReq = {
            "workbenchId": wbId,
            "search": {
                "query": searchTerm
            },
            "count": (reqType === 'allFacets') ? 40 : 0,
            "sortOrder": sortOrder,
            "facets": (reqType === 'allFacets') ? allFacetsProp : pubFacetsProp
        };
        genericReq.search = {
            "query": searchTerm,
            "fulltext": false,
            "publications": this.preparePublicationFilters(),
            "products": this.prepareDatabaseFilters(),
            "filters": this.prepareFiltersV1()
        };
        this.applyDateFilters(genericReq);
        this.applyFullTextLimiter(genericReq);
        return genericReq;
    }
    generateWosSearchRequest(searchTerm = '', wbId, sortOrder, sourceFilters, docFilters, pubFilters) {
        console.log(" Inside WOS SearchRequest pubFilters >>>>", JSON.stringify(pubFilters));
        const genericReq = {
            "workbenchId": wbId,
            "search": {
                "query": searchTerm
            },
            "count": 40,
            "sortOrder": sortOrder,
            "facets": [
                { "name": "sourcetype" },
                { "name": "pubTitle" },
                { "name": "objectype" }
            ]
        };
        console.log("preparing filers string WOS >>>>", this.prepareFilters(sourceFilters, docFilters, pubFilters));
        genericReq.search = {
            "query": searchTerm,
            "fulltext": false,
            "products": [{ "moniker": "tdmwos", "name": "TDM WoS" }],
            "filters": this.prepareFilters(sourceFilters, docFilters, pubFilters)
        };
        this.applyDateFilters(genericReq);
        return genericReq;
    }
    //ApplyFullTextLimiter
    applyFullTextLimiter(searchReq) {
        let limiter = JSON.parse(localStorage.getItem('fullTextLimiter')) === null ? false : JSON.parse(localStorage.getItem('fullTextLimiter'));
        searchReq.search.fulltext = limiter;
    }
    //applyDateFilters
    applyDateFilters(searchReq) {
        let fDate = JSON.parse(localStorage.getItem('fromDate'));
        let tDate = JSON.parse(localStorage.getItem('toDate'));
        if (fDate != null && fDate.length > 0)
            searchReq.search.startDate = new Date(fDate);
        if (tDate != null && tDate.length > 0)
            searchReq.search.endDate = new Date(tDate);
    }
    storeSearchRequest(searchReq) {
        localStorage.setItem('sReq', JSON.stringify(searchReq));
    }
    storeWosSearchRequest(searchReq) {
        localStorage.setItem('sReqWos', JSON.stringify(searchReq));
    }
    reteriveSearchRequest() {
        return JSON.parse(localStorage.getItem('sReq'));
    }
    currentSearchTerm() {
        let docReq = this.reteriveSearchRequest();
        if (docReq === null) {
            let defaultSearchTerm = localStorage.getItem('searchValue');
            //localStorage.removeItem('searchValue');
            return defaultSearchTerm;
        }
        return docReq != null ? docReq.search.query : '';
    }
    clearSearchRequest() {
        localStorage.removeItem('sReq');
    }
    clearRefinementData() {
        this.clearFilters();
        this.clearSearchRequest();
    }
    prepareFilters(sourceFilter, docFilter, pubFilter, dbFilter) {
        let filters = [];
        if (pubFilter != null && pubFilter.length > 0) {
            let pFilters = JSON.parse(pubFilter);
            if (pFilters != null && pFilters.length > 0) {
                let pf = {
                    "name": "pubTitle",
                    "entries": pFilters
                };
                filters.push(pf);
            }
        }
        if (sourceFilter != null && sourceFilter.length > 0) {
            let sFilters = JSON.parse(sourceFilter);
            if (sFilters != null && sFilters.length > 0) {
                let sf = {
                    "name": "sourcetype",
                    "entries": sFilters
                };
                filters.push(sf);
            }
        }
        if (docFilter != null && docFilter.length > 0) {
            let sDocFilters = JSON.parse(docFilter);
            if (sDocFilters != null && sDocFilters.length > 0) {
                let df = {
                    "name": "objectype",
                    "entries": sDocFilters
                };
                filters.push(df);
            }
        }
        if (dbFilter != null && dbFilter.length > 0) {
            let dbFilters = JSON.parse(dbFilter);
            if (dbFilters != null && dbFilters.length > 0) {
                let df = {
                    "name": "bundles",
                    "entries": dbFilters
                };
                filters.push(df);
            }
        }
        return filters;
    }
    prepareDatabaseFilters() {
        let products = [];
        //DbFilter...
        from(this.dbFilterValues())
            .pipe(map(val => ({ "moniker": val.moniker, "name": val.name })), tap(val => console.log(val)), toArray()).subscribe(val => {
            products = val;
        });
        return products;
    }
    preparePublicationFilters() {
        let publications = [];
        from(this.pubFilterValues())
            .pipe(map(val => ({ "searchValue": val.id })), toArray()).subscribe(val => {
            publications = val;
        });
        return publications;
    }
    prepareFiltersV1() {
        //sourceFilter?: string, docFilter?: string, pubFilter?:string, dbFilter?: string
        let pubFilter, dbFilter, docFilter, sourceFilter = '';
        let filters = [];
        //SourceFilters... (sourceType)
        from(this.sourceFilterValues())
            .pipe(map(val => ({ "searchValue": val })), tap(val => console.log(val)), toArray(), map(val => JSON.stringify(val)), tap(val => console.log(val))).subscribe(val => {
            sourceFilter = val;
        });
        if (sourceFilter != null && sourceFilter.length > 0) {
            let sFilters = JSON.parse(sourceFilter);
            if (sFilters != null && sFilters.length > 0) {
                let sf = {
                    "name": "sourcetype",
                    "entries": sFilters
                };
                filters.push(sf);
            }
        }
        //DocFilters... (objectType)
        from(this.docFilterValues())
            .pipe(map(val => ({ "searchValue": val })), tap(val => console.log(val)), toArray(), map(val => JSON.stringify(val)), tap(val => console.log(val))).subscribe(val => {
            docFilter = val;
        });
        if (docFilter != null && docFilter.length > 0) {
            let sDocFilters = JSON.parse(docFilter);
            if (sDocFilters != null && sDocFilters.length > 0) {
                let df = {
                    "name": "objectype",
                    "entries": sDocFilters
                };
                filters.push(df);
            }
        }
        return filters;
    }
    setDocCount(noOfHits) {
        this.docCountSubject.next(noOfHits);
    }
    setPubFacetSubject(entry) {
        this.pubFacetSubject.next(entry);
    }
    setLazyPubFacetSubject(facets) {
        console.log("LazyPubFacetSubject >>>> ", JSON.stringify(facets));
        this.lazyPubFacetSubject.next(facets);
    }
    setLazyPubFacetSubjectV1(pubFacetRespType) {
        console.log("LazyPubFacetSubject from [-refinement.service.ts] >>>> ", JSON.stringify(pubFacetRespType));
        this.lazyPubFacetSubjectV1.next(pubFacetRespType);
    }
    setSourceFacetSubject(entry) {
        this.sourceFacetSubject.next(entry);
    }
    setObjectFacetSubject(entry) {
        this.objectFacetSubject.next(entry);
    }
    setSourceFilterSubject(sourceFilters) {
        this.sourceTypeFilterSubject.next(sourceFilters);
    }
    setDbFilterSubject(dbFilters) {
        this.dbFilterSubject.next(dbFilters);
    }
    setDbProductSubject(dbFilters) {
        this.dbProductSubject.next(dbFilters);
    }
    setDocTypeFilterSubject(docTypeFilters) {
        this.docTypeFilterSubject.next(docTypeFilters);
    }
    setPubFilterSubject(pubFilters) {
        this.pubFilterSubject.next(pubFilters);
    }
    setDateFilterChange(b) {
        this.dateFilterSubject.next(true);
    }
    setFullTextLimiterChange(val) {
        this.fullTextLimiterSubject.next(val);
    }
    setPubListUpdated(updated) {
        this.pubListUpdatedSubject.next(updated);
    }
    setDateRangeSubject(dateRange) {
        this.dateRangeSubject.next(dateRange);
    }
    getPublications(filter, wbId, pageSize, offset) {
        var pubRequest = {
            workbenchId: wbId,
            search: { query: filter },
            count: pageSize,
            offset: offset
        };
        const apiURL = this.pubApiRootForCm;
        return this.http.post(apiURL, pubRequest).pipe(shareReplay(2));
    }
    getTopSearches(wbId) {
        let url = "/api/wbm/workbench/" + wbId + "/databases/popular";
        return this.http.get(url);
    }
    getDatabaseSubscription(wbId) {
        //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
        let url = "/api/wbm/workbench/" + wbId + "/subscription/flat";
        return this.http.get(url).pipe(tap(db => console.log('db==>', db)), shareReplay(1));
    }
    getDatabaseSubscription2(wbId) {
        //    /studioworkbenchmanager/public/workbench/devlt2/subscription/flat
        let url = "/api/wbm/workbench/" + wbId + "/subscription";
        return this.http.get(url);
    }
    getGisSubscription(wbId) {
        let url = "/api/wbm/workbench/" + wbId + "/subscription/gis";
        return this.http.get(url);
    }
    getMonikerInfo(moniker) {
        let url = "/api/wbm/product/" + moniker;
        return this.http.get(url).pipe(tap(db => console.log('getting moniker =>', db)), shareReplay(1));
    }
    getGisInfo(gisList) {
        let gisInfoList = [];
        for (let gis of gisList) {
            switch (gis.moniker) {
                case "hearingsa": {
                    gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 1824-1979; the module also includes unpublished hearings from the U.S. House (1832-1971) and Senate (1824-1979).";
                    break;
                }
                case "hearingsb": {
                    gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 1980-2003; the module also includes three collections of unpublished hearings from the U.S. Senate from 1980-1984.";
                    break;
                }
                case "hearingsc": {
                    gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings 2004-2010.";
                    break;
                }
                case "hearingsd2011": {
                    gis.description = "Witnesses testimony offers views on the issues of the day, including the perspectives of administration officials, experts, representatives of business and labor, advocacy organizations and ordinary citizens. Collection of published hearings released by GPO and collected by ProQuest in 2011.";
                    break;
                }
            }
        }
        return gisList;
    }
    getPubFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'pubTitle');
    }
    getTotalPublications(facets) {
        return this.extractSubjectsFromFacets(facets, 'pubTitle').length;
    }
    getSourceFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'sourcetype');
    }
    getDocTypeFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'objectype');
    }
    getDbFacets(facets) {
        return this.extractSubjectsFromFacets(facets, 'databases');
    }
    extractSubjectsFromFacets(facets, facetType) {
        if (facets != null && facets.length > 0)
            return facets.find(facet => facet.name === facetType) ? facets.find(facet => facet.name === facetType).entries : [];
        return [];
    }
    clearFilters() {
        localStorage.removeItem('sourceFilters');
        localStorage.removeItem('docFilters');
        localStorage.removeItem('pubFilters');
        localStorage.removeItem('fromDate');
        localStorage.removeItem('toDate');
        localStorage.removeItem('fDate');
        localStorage.removeItem('tDate');
        localStorage.removeItem('fullTextLimiter');
        localStorage.removeItem('vPubFilters');
        localStorage.removeItem('yearCardSel');
        localStorage.removeItem('dbFilters');
        localStorage.removeItem('selectedDbList');
        localStorage.removeItem('dbDisplayFilters');
        this.setPubListUpdated(false);
    }
    setFilterChecked(checked) {
        this.displayFilterSubject.next(checked);
    }
    getSelectedPubIds() {
        return (localStorage.getItem('selectedPubs') === null ? [] : JSON.parse(localStorage.getItem('selectedPubs')));
    }
    displayClearFilters() {
        let sf = JSON.parse(localStorage.getItem('sourceFilters'));
        let df = JSON.parse(localStorage.getItem('docFilters'));
        let fromDate = JSON.parse(localStorage.getItem('fromDate'));
        let toDate = JSON.parse(localStorage.getItem('toDate'));
        let fullTextLimiter = JSON.parse(localStorage.getItem('fullTextLimiter'));
        if ((sf != null && sf.length > 0) ||
            (df != null && df.length > 0) ||
            (fromDate != null && fromDate.length > 0) ||
            (toDate != null && toDate.length > 0) ||
            (fullTextLimiter === true) ||
            (this.pubListUpdatedSubject.getValue())) {
            return true;
        }
    }
    isDatesSelected() {
        let fromDate = JSON.parse(localStorage.getItem('fDate'));
        let toDate = JSON.parse(localStorage.getItem('tDate'));
        if ((fromDate != null && fromDate.length > 0) ||
            (toDate != null && toDate.length > 0))
            return true;
    }
    getSelectedDbList() {
        return localStorage.getItem('selectedDbList') !== null ? JSON.parse(localStorage.getItem('selectedDbList')) : [];
    }
    createDataset(dataset) {
        const apiUrl = 'api/cm/corpus';
        return this.http.post(apiUrl, dataset);
    }
    createWosDataset(dataset) {
        const apiUrl = 'api/cm/corpus/wos';
        return this.http.post(apiUrl, dataset);
    }
    createGis(dataset) {
        const apiUrl = 'api/cm/corpus/gis';
        return this.http.post(apiUrl, dataset);
    }
}
PublicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicationService_Factory() { return new PublicationService(i0.ɵɵinject(i1.HttpClient)); }, token: PublicationService, providedIn: "root" });
